import { ReactNode } from 'react';
import Link from 'next/link';
import { Maybe } from 't2-graphql-types';
import Typo from '../Typography';

export type TNotificationCardProps = {
  title: ReactNode;
  content?: Maybe<string>;
  createdAt: string;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  url: string;
  onClick?: () => void;
};

export const NotificationCard = ({
  title,
  content,
  createdAt,
  leftAdornment,
  rightAdornment,
  url,
  onClick,
}: TNotificationCardProps) => (
  <Link href={url} onClick={onClick}>
    <div className="flex gap-2 py-3 px-2 mt-4 mx-5 border border-gray-200 rounded-sm bg-white-900 hover:bg-gray-200">
      {leftAdornment}
      <div className="flex flex-col w-full gap-1.5">
        {title}
        <Typo className="text-[12px] multiline-ellipsis md:line-clamp-2 pr-11">{content}</Typo>
        <Typo className="text-[9px] text-gray-400">{createdAt}</Typo>
      </div>
      {rightAdornment}
    </div>
  </Link>
);
