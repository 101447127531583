import { TResolveProfileStrategy } from '../types';

export const resolveDefaultProfileStrategy: TResolveProfileStrategy = ({
  defaultProfile,
  allProfilesOwnedByAddress,
}) => {
  if (defaultProfile) {
    return defaultProfile;
  }

  return allProfilesOwnedByAddress?.[0] || null;
};
