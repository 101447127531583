import { showNotification } from '../NotificationBanner/showNotification';

export const updateProfileExceptionNotification = () =>
  showNotification({
    type: 'error',
    title: 'Error message',
    message: `Unable to connect user with lens profile`,
  });

export const privyUserWalletMissingExceptionNotification = () =>
  showNotification({
    type: 'error',
    title: 'Error message',
    message:
      'We could not connect with your wallet provider. Please ensure correct wallet is connected, or try to sign in again.',
  });

export const lensProfileNotFoundExceptionNotification = () =>
  showNotification({
    type: 'error',
    title: 'Error message',
    message: "We didn't find any Lens account associated with your wallet",
  });

export const sessionAndWalletAddressMismatchExceptionNotification = () =>
  showNotification({
    type: 'error',
    title: 'Error message',
    message: 'Wallet address mismatch. Please ensure correct wallet is connected.',
  });

export const walletClientNotFoundExceptionNotification = () =>
  showNotification({
    type: 'error',
    title: 'Error message',
    message: 'Wallet client not found. Please ensure correct wallet is connected.',
  });

export const unexpectedExceptionNotification = () =>
  showNotification({
    type: 'error',
    title: 'Error message',
    message: 'An unexpected error occurred while connecting with Lens.',
  });

export const alreadyProcessingExceptionNotification = () =>
  showNotification({
    type: 'error',
    title: 'Error message',
    message: 'Already processing. Please proceed in wallet provider.',
  });

export const missingProfileExceptionNotification = () =>
  showNotification({
    type: 'error',
    title: 'Error',
    message: 'There is no Lens profile owned by active wallet address',
  });

export const walletConnectionDeclinedExceptionNotification = () => {
  showNotification({
    type: 'error',
    title: 'Error',
    message: 'Wallet connection declined. Please ensure your wallet provider is connected.',
  });
};

export const walletConnectionWrongAccountExceptionNotification = () => {
  showNotification({
    type: 'error',
    title: 'Error',
    message: 'Wallet connection failed - connected account mismatch. Please ensure correct wallet is connected.',
  });
};
