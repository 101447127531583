import React from 'react';
import { getCommonPropsForPropsProvider } from './getCommonPropsForPropsProvider';
import { NotificationCardTitleWithInitiator } from '../NotificationCardTitleWithInitiator';
import { NotificationProfileImageWithBadge } from '../NotificationProfileImageWithBadge';
import { TCloudinaryImageShape } from '../../CloudinaryResponsiveImage/types';
import { CommentBadgeIcon } from '../CommentBadgeIcon';
import { getArticlePageUrl } from '../../../services/pagesUrl/getArticlePageUrl';
import { COMMENTS_SECTION_ID } from '../../CommentsSection/utils/constants';
import { TNotificationCardPropsProvider } from '../types';

export const newCommentOnPostPropsProvider: TNotificationCardPropsProvider = ({
  notification,
  sendEvent,
  closeAsidePanel,
}) => {
  const { createdAt, onClick, initiatorName, profileImage } = getCommonPropsForPropsProvider({
    notification,
    sendEvent,
    closeAsidePanel,
  });

  const { relatedComment } = notification;

  return {
    createdAt,
    onClick,
    title: <NotificationCardTitleWithInitiator initiatorName={initiatorName} content="commented on your post" />,
    content: relatedComment?.content as string,
    leftAdornment: (
      <NotificationProfileImageWithBadge
        image={profileImage as TCloudinaryImageShape}
        badgeIcon={<CommentBadgeIcon />}
      />
    ),
    url: `${getArticlePageUrl(relatedComment?.article?.slug as string)}#${COMMENTS_SECTION_ID}`,
  };
};
