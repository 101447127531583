import { cloneElement, FC, ReactElement } from 'react';
import { useBlockForDeactivatedUser } from './hooks/useBlockForDeactivatedUser';

type TProtectWithLoginProps = {
  children: ReactElement;
};

export const BlockForDeactivatedUser: FC<TProtectWithLoginProps> = ({ children, ...props }) => {
  const { shouldBlock, handleBlockedClick } = useBlockForDeactivatedUser();

  return cloneElement(children, {
    ...props,
    ...(shouldBlock ? { onClick: handleBlockedClick } : {}),
  });
};
