import classNames from 'classnames';
import { ReactNode } from 'react';

type TPosition = 'top-right' | 'bottom-right';

type TBadgeImageProps = {
  position?: TPosition;
  icon: ReactNode;
};

export const BadgeIcon = ({ position = 'top-right', icon }: TBadgeImageProps) => (
  <span
    className={classNames('absolute inline-block', {
      'top-0 right-0': position === 'top-right',
      'bottom-1 right-0': position === 'bottom-right',
    })}
  >
    {icon}
  </span>
);
