/* eslint-disable no-nested-ternary */
import cx from 'classnames';
import Image from 'next/image';
import CloudinaryResponsiveImage from '../CloudinaryResponsiveImage/CloudinaryResponsiveImage';
import { IArticleAuthorProfileImageProps, TAvatarSize } from './types';

export const DEFAULT_AVATAR = '/images/default-avatar.webp';

const UserProfileImage: React.FC<IArticleAuthorProfileImageProps> = ({
  profileImage,
  size = 'large',
  className,
  isVisible,
}) => {
  const avatarSizeVariants: Record<TAvatarSize, string> = {
    small: 'w-[24px] h-[24px]',
    medium: 'w-[32px] h-[32px]',
    large: 'w-[32px] h-[32px] md:w-[40px] md:h-[40px]',
  };

  return (
    <div className={cx('rounded-full', avatarSizeVariants[size], [className])}>
      {profileImage?._meta ? (
        <CloudinaryResponsiveImage
          containerClassName="h-[100%] w-[100%]"
          className="rounded-full object-cover w-[100%] h-[100%]"
          alt="user profile photo"
          image={profileImage}
          isVisible={isVisible}
        />
      ) : profileImage?.publicUrlTransformed ? (
        <img className="w-full h-full rounded-full object-cover" src={profileImage?.publicUrlTransformed} alt="" />
      ) : (
        <Image
          src={DEFAULT_AVATAR}
          height={104}
          width={104}
          alt="default avatar"
          objectFit="cover"
          className="rounded-full"
        />
      )}
    </div>
  );
};

export default UserProfileImage;
