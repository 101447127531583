import { useAsidePanelContext } from '../AsidePanel/contexts/AsidePanelContext';
import { NavbarNotificationBell } from './NavbarNotificationBell';
import { disableGlobalScrollbar } from '../Modal/utils/disableGlobalScrollbar';
import { useNotificationBellContext } from './NavbarNotificationBellContext';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

export const NavbarNotificationBellContainer = () => {
  const { openAsidePanel } = useAsidePanelContext();
  const { hasNotifications } = useNotificationBellContext();
  const { sendEvent } = useAnalytics();

  const notificationBellClickHandler = () => {
    sendEvent('notifications_panel_open');
    openAsidePanel();
    disableGlobalScrollbar('auto');
  };

  return <NavbarNotificationBell onClick={notificationBellClickHandler} hasNotifications={hasNotifications} />;
};
