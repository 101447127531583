import { NotificationType } from '../constants';
import { newReplyToCommentPropsProvider } from './newReplyToCommentPropsProvider';
import { TNotificationCardPropsProvider } from '../types';
import { newCommentOnPostPropsProvider } from './newCommentOnPostPropsProvider';
import { newCommentOnPromptPropsProvider } from './newCommentOnPromptPropsProvider';
import { newPromptInTerritoryPropsProvider } from './newPromptInTerritoryPropsProvider';
import { newPostInTerritoryPropsProvider } from './newPostInTerritoryPropsProvider';
import { newReplyToPromptPropsProvider } from './newReplyToPromptPropsProvider';
import { newPromptLikePropsProvider } from './newPromptLikePropsProvider';
import { newPostLikePropsProvider } from './newPostLikePropsProvider';
import { newCommentLikePropsProvider } from './newCommentLikePropsProvider';
import { newQuoteReplyToPostProvider } from './newQuoteReplyToPostProvider';
import { newCollectTransactionStatusPropsProvider } from './newCollectTransactionStatusPropsProvider';

// :todo remove Partial when all types implemented
const NotificationCardPropsProviders: Partial<Record<NotificationType, TNotificationCardPropsProvider>> = {
  [NotificationType.NEW_REPLY_TO_COMMENT]: newReplyToCommentPropsProvider,
  [NotificationType.NEW_COMMENT_ON_POST]: newCommentOnPostPropsProvider,
  [NotificationType.NEW_COMMENT_ON_PROMPT]: newCommentOnPromptPropsProvider,
  [NotificationType.NEW_PROMPT_IN_TERRITORY]: newPromptInTerritoryPropsProvider,
  [NotificationType.NEW_POST_IN_TERRITORY]: newPostInTerritoryPropsProvider,
  [NotificationType.NEW_REPLY_TO_PROMPT]: newReplyToPromptPropsProvider,
  [NotificationType.NEW_PROMPT_LIKE]: newPromptLikePropsProvider,
  [NotificationType.NEW_POST_LIKE]: newPostLikePropsProvider,
  [NotificationType.NEW_COMMENT_LIKE]: newCommentLikePropsProvider,
  [NotificationType.NEW_REPLY_TO_QUOTE]: newQuoteReplyToPostProvider,
  [NotificationType.NEW_COLLECT_TRANSACTION_STATUS]: newCollectTransactionStatusPropsProvider,
};

export const getPropsProviderForNotificationCard = (type: NotificationType) => NotificationCardPropsProviders[type];
