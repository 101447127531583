import { gql } from '@apollo/client';

export const getGetAllTerritoriesNavbarInfo = gql`
  query getGetAllTerritoriesNavbarInfo {
    territories(orderBy: { createdAt: asc }) {
      id
      name
      slug
      createdAt
    }
  }
`;
