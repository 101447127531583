import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { TerritoryVersionType } from '../../layouts/TerritoryPageLayout/TerritoryPageLayout';
import { useUserTerritoryPermissions } from '../Territory/services/useUserTerritoryPermissions';
import { TUserTerritoryPermissions } from '../Territory/types';

export const TerritoryHomePageContext = createContext<TTerritoryHomePageContextType>(
  {} as TTerritoryHomePageContextType,
);

type TTerritoryHomePageContextType = {
  territoryVersion: TerritoryVersionType;
  territoryId: string;
  userTerritoryPermissions: TUserTerritoryPermissions;
  territorySlug: string;
};

type TTerritoryHomePageContextProviderProps = {
  children: ReactNode;
  territoryVersion: TerritoryVersionType;
  territoryId: string;
  territorySlug: string;
};

export const TerritoryHomePageContextProvider = ({
  children,
  territoryVersion,
  territoryId,
  territorySlug,
}: TTerritoryHomePageContextProviderProps) => {
  const userTerritoryPermissions = useUserTerritoryPermissions({
    territoryId,
  });
  const value = useMemo(
    () => ({
      territoryVersion,
      territoryId,
      userTerritoryPermissions,
      territorySlug,
    }),
    [territoryVersion, territoryId, territorySlug, userTerritoryPermissions],
  );

  return <TerritoryHomePageContext.Provider value={value}>{children}</TerritoryHomePageContext.Provider>;
};

export const useTerritoryHomePageContext = () => useContext(TerritoryHomePageContext);
