import cx from 'classnames';
import Link from 'next/link';
import { hoverAnimationClassNames } from '../../../utils/hoverAnimationClassNames';
import styles from './LinkButton.module.css';

export interface LinkButtonProps {
  href: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  target?: string;
  rel?: string;
  variant?: 'primary' | 'secondary' | 'ghost';
  disabled?: boolean;
  onClick?: () => void;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  href,
  title,
  className,
  target,
  rel,
  variant = 'primary',
  children,
  disabled = false,
  onClick,
  ...props
}) => (
  <Link
    className={cx(
      'relative flex items-center font-secondary w-max',
      'py-2 px-3 text-xs leading-5 md:text-base md:leading-6',
      'rounded-4xl',
      {
        [styles.disabled]: disabled,
      },
      styles[variant],
      hoverAnimationClassNames,
      className,
    )}
    href={href}
    target={target}
    rel={rel}
    onClick={onClick}
    {...props}
  >
    {title || children}
  </Link>
);
export default LinkButton;
