import { Placeholder } from '../Placeholder/Placeholder';

const NavbarRightSectionPlaceholder = () => (
  <>
    <Placeholder className="w-8 md:w-10 h-8 md:h-10 rounded-full" />
    <Placeholder className="w-8 md:w-10 h-8 md:h-10 rounded-full" />
    <Placeholder className="w-8 md:w-[145px] h-8 md:h-10 rounded-[31px]" />
  </>
);

export default NavbarRightSectionPlaceholder;
