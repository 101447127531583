import classNames from 'classnames';
import { ReactNode } from 'react';

const CloseAsidePanel = ({ onClick }: { onClick: () => void }) => (
  <button
    type="button"
    onClick={onClick}
    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm inline-flex items-center"
  >
    <svg
      aria-hidden="true"
      className="w-5 h-5"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" />
    </svg>
    <span className="sr-only">Close modal</span>
  </button>
);

export type TPlacementProps = 'left' | 'right';

export type TAsidePanelProps = {
  children: ReactNode;
  isOpen?: boolean;
  onClose: () => void;
  containerClassName?: string;
  placement?: TPlacementProps;
};

export const AsidePanel = ({
  children,
  isOpen,
  onClose,
  containerClassName,
  placement = 'right',
}: TAsidePanelProps) => {
  const isPlacementLeft = placement === 'left';

  return (
    <div
      className={classNames(
        'fixed top-0 left-0 right-0 bottom-0 z-50 overflow-hidden w-full h-full max-h-screen bg-gray-500 bg-opacity-25 py-7 ease-in-out transition-all',
        { visibile: isOpen },
        { invisible: !isOpen },
      )}
      onClick={onClose}
    >
      <div
        className={classNames(
          'absolute top-0 flex flex-col py-9 w-full sm:w-[500px] h-full bg-white-100 shadow-staticShadow transition-transform ease-in-out',
          {
            'translate-x-0 right-0': isOpen && !isPlacementLeft,
            'translate-x-full right-0': !isOpen && !isPlacementLeft,
          },
          {
            '-translate-x-0 left-0': isOpen && isPlacementLeft,
            '-translate-x-full left-0': !isOpen && isPlacementLeft,
          },
          containerClassName,
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex relative items-center justify-center w-full">
          <div className="absolute right-6 top-0">
            <CloseAsidePanel onClick={onClose} />
          </div>
        </div>
        <div className="overflow-y-auto h-full flex justify-center">{children}</div>
      </div>
    </div>
  );
};
