import React, { FC } from 'react';
import Link from 'next/link';
import Typo from '../Typography';
import { SparksIcon } from '../Icons/SparksIcon';
import { colors } from '../Spinner/utils/colors';

const TERRITORY_UPDATE_URL = 'https://app.t2.world/article/cm2ancmj1730911zmcs12bhx74';

type TTerritoryBannerProps = {
  onClick: () => void;
};

export const TerritoryBanner: FC<TTerritoryBannerProps> = ({ onClick }) => (
  <div className="flex items-center justify-center gap-1">
    <SparksIcon className="w-5 h-5" stroke={colors?.t2?.neutral?.grey08} />
    <Typo variant="small-p" className="py-2 !text-sm">
      Territories just got a fresh new look! Read more about the changes{' '}
      <Link onClick={onClick} href={TERRITORY_UPDATE_URL} className="underline">
        here
      </Link>
    </Typo>
  </div>
);
