import { FC } from 'react';

export const CollectBadgeIcon: FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16.5" rx="8" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3672 6.49543C11.3556 6.43274 11.3267 6.37663 11.2852 6.33629C11.2437 6.29595 11.192 6.27379 11.1386 6.27344H8.94125C8.88027 6.27599 8.8225 6.30693 8.78011 6.35972C8.73772 6.41251 8.71403 6.48303 8.71403 6.55643C8.71403 6.62982 8.73772 6.70034 8.78011 6.75313C8.8225 6.80592 8.88027 6.83686 8.94125 6.83941H10.9486L11.4714 9.66929H9.25524C9.2165 9.66923 9.17835 9.68072 9.1442 9.70273C9.11004 9.72474 9.08096 9.75658 9.05953 9.7954L8.81526 10.2353H7.18503L6.94075 9.7954C6.91933 9.75658 6.89024 9.72474 6.85609 9.70273C6.82194 9.68072 6.78379 9.66923 6.74504 9.66929H4.52884L5.05168 6.83941H7.05903C7.12001 6.83686 7.17779 6.80592 7.22018 6.75313C7.26256 6.70034 7.28625 6.62982 7.28625 6.55643C7.28625 6.48303 7.26256 6.41251 7.22018 6.35972C7.17779 6.30693 7.12001 6.27599 7.05903 6.27344H4.86283C4.80945 6.27379 4.75775 6.29595 4.71622 6.33629C4.67469 6.37663 4.64579 6.43274 4.63426 6.49543L4.00571 9.89128C4.002 9.91121 4 12.2163 4 12.2163C4 12.2914 4.0248 12.3635 4.06895 12.4166C4.1131 12.4697 4.17298 12.4995 4.23542 12.4995H11.7646C11.827 12.4995 11.8869 12.4697 11.931 12.4166C11.9752 12.3635 12 12.2914 12 12.2163C12 12.2163 11.998 9.91121 11.9943 9.89128L11.3672 6.49543ZM11.5294 11.9335H4.47084V10.2356H6.61933L6.86304 10.6755C6.88454 10.7142 6.91364 10.746 6.94778 10.768C6.98191 10.79 7.02002 10.8015 7.05875 10.8016H8.94097C8.97969 10.8015 9.01781 10.79 9.05194 10.768C9.08607 10.746 9.11518 10.7142 9.13667 10.6755L9.38038 10.2356H11.5289L11.5294 11.9335Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.89303 7.48853C6.84896 7.54173 6.82422 7.61377 6.82422 7.68887C6.82422 7.76397 6.84896 7.83601 6.89303 7.88921L7.83414 9.02116C7.87838 9.07417 7.93827 9.10393 8.00071 9.10393C8.06315 9.10393 8.12304 9.07417 8.16727 9.02116L9.10838 7.88921C9.15002 7.83547 9.17269 7.76439 9.17161 7.69094C9.17053 7.6175 9.14579 7.54742 9.10261 7.49548C9.05942 7.44353 9.00116 7.41378 8.9401 7.41249C8.87903 7.41119 8.81993 7.43845 8.77525 7.48853L8.23584 8.13767V4.29302C8.23694 4.25503 8.23167 4.21716 8.22035 4.18167C8.20902 4.14618 8.19186 4.1138 8.16991 4.08645C8.14795 4.05911 8.12164 4.03736 8.09255 4.02251C8.06345 4.00765 8.03217 4 8.00057 4C7.96896 4 7.93768 4.00765 7.90858 4.02251C7.87949 4.03736 7.85318 4.05911 7.83122 4.08645C7.80927 4.1138 7.79211 4.14618 7.78079 4.18167C7.76946 4.21716 7.76419 4.25503 7.76529 4.29302V8.13767L7.22616 7.48853C7.18193 7.43552 7.12204 7.40576 7.0596 7.40576C6.99716 7.40576 6.93727 7.43552 6.89303 7.48853Z"
      fill="black"
    />
  </svg>
);
