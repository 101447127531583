import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { SearchForm } from '../SearchForm/SearchForm';
import { SearchHistory } from '../SearchHistory/SearchHistory';

type TSearchFormContainerProps = {
  closeModal: () => void;
};

export const SearchFormContainer: FC<TSearchFormContainerProps> = ({ closeModal }) => {
  const defaultValues = {
    search: '',
  };

  const useFormReturn = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  return (
    <div className="mx-1 mb-1 mt-10 sm:mb-0 sm:mt-1">
      <SearchForm useFormReturn={useFormReturn} closeModal={closeModal} />
      <SearchHistory closeModal={closeModal} />
    </div>
  );
};
