export const NavbarNotificationBellIcon = () => (
  <svg className="w-4 h-4 md:w-5 h-5" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 6.66666C15 5.34057 14.4732 4.0688 13.5355 3.13112C12.5979 2.19344 11.3261 1.66666 10 1.66666C8.67392 1.66666 7.40215 2.19344 6.46447 3.13112C5.52678 4.0688 5 5.34057 5 6.66666C5 12.5 2.5 14.1667 2.5 14.1667H17.5C17.5 14.1667 15 12.5 15 6.66666Z"
      stroke=""
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4414 17.5C11.2949 17.7526 11.0846 17.9622 10.8316 18.1079C10.5786 18.2537 10.2918 18.3304 9.99977 18.3304C9.70779 18.3304 9.42093 18.2537 9.16792 18.1079C8.9149 17.9622 8.70461 17.7526 8.55811 17.5"
      stroke=""
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
