import classNames from 'classnames';
import { FC, HTMLProps } from 'react';

type TNavbarSectionProps = HTMLProps<HTMLDivElement> & {
  isVisible?: boolean;
};

export const NavbarSection: FC<TNavbarSectionProps> = ({ isVisible = true, className, ...props }) => (
  <div
    className={classNames('flex flex-row items-center gap-x-4 lg:gap-x-5', { invisible: !isVisible }, className)}
    {...props}
  />
);
