import { ReactNode } from 'react';
import { useAsidePanelContext } from './contexts/AsidePanelContext';
import { AsidePanel, TPlacementProps } from './AsidePanel';

type TAsidePanelContainerProps = {
  children: ReactNode;
  containerClassName?: string;
  placement?: TPlacementProps;
};

export const AsidePanelContainer = ({ children, containerClassName, placement }: TAsidePanelContainerProps) => {
  const { isOpen, closeAsidePanel } = useAsidePanelContext();

  return (
    <AsidePanel isOpen={isOpen} onClose={closeAsidePanel} containerClassName={containerClassName} placement={placement}>
      {children}
    </AsidePanel>
  );
};
