import React, { ReactNode, forwardRef } from 'react';
import cx from 'classnames';
import Typo from '../Typography';

export type TDropdownLableBackgroundColorVariant = 'white' | 'transparent';

interface IDropdownLabelProps {
  label?: ReactNode;
  rightAdornment?: ReactNode;
  className?: string;
  isActive?: boolean;
  // withBorder is deprecated. New dropdowns should not have a border.
  withBorder?: boolean;
  labelClassName?: string;
  backgroundColor?: TDropdownLableBackgroundColorVariant;
}

const DropdownLabel = forwardRef<HTMLDivElement, IDropdownLabelProps>(
  ({ className, label, rightAdornment, isActive, withBorder, labelClassName, backgroundColor = 'white' }, ref) => (
    <div
      ref={ref}
      className={cx(
        'flex items-center  justify-center rounded-[31px] text-black-700  hover:bg-gray-200',
        'px-4 py-2',
        'transition duration-200',
        {
          'bg-white-900': !isActive && backgroundColor === 'white',
          'bg-transparent': !isActive && backgroundColor === 'transparent',
          'bg-gray-200': isActive,
          'border border-gray-400': withBorder,
        },
        className,
      )}
    >
      <Typo tag="div" variant="small-p" className={cx('w-full text-xs md:!text-[16px]', labelClassName)}>
        {label}
      </Typo>
      <div>{rightAdornment}</div>
    </div>
  ),
);
export default DropdownLabel;
