import { FC } from 'react';
import Link from 'next/link';
import { getLinkRelAttributeValue } from 'utils/getLinkRelAttributeValue';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import UserProfileImage from '../UserProfileImage/UserProfileImage';
import { getUserPageUrl } from '../../services/pagesUrl/getUserPageUrl';

type TNavbarUserDetailsDropdownItem = {
  profileImage: TCloudinaryImageShape | undefined;
  timePoints: number;
  username?: string;
  publicAddress?: string;
};

export const NavbarUserDetailsDropdownItem: FC<TNavbarUserDetailsDropdownItem> = ({
  profileImage,
  timePoints,
  username,
}) => {
  const userPageUrl = getUserPageUrl(username!);

  return (
    <Link
      href={userPageUrl}
      rel={getLinkRelAttributeValue(userPageUrl)}
      className="bg-green-100 hover:bg-green-300 py-2 px-3 md:py-3 md:px-4 flex gap-2 md:gap-4 w-full justify-between items-center cursor-pointer"
    >
      <div className="flex items-center gap-2 md:gap-4">
        <div className="w-8 h-8">
          <UserProfileImage size="medium" profileImage={profileImage} />
        </div>
        <span className="font-secondary text-xs md:text-base whitespace-nowrap text-ellipsis max-w-[70px] md:max-w-[100px] overflow-hidden">
          My profile
        </span>
      </div>
      <span className="font-secondary font-semibold text-xs md:text-base text-green-600 whitespace-nowrap grow">
        {Math.floor(timePoints)} t.p.
      </span>
    </Link>
  );
};
