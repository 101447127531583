import cx from 'classnames';
import { transitionAnimationClassNames } from '../../../utils/transitionAnimationClassNames';

export const WindowsShortcutIcon = () => (
  <svg width="59" height="18" viewBox="0 0 59 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.5"
      width="38"
      height="17"
      rx="3.5"
      fill="#F4F4F4"
      className={cx('fill-t2-shades-white group-hover:fill-t2-neutral-grey02', transitionAnimationClassNames)}
    />
    <rect x="0.5" y="0.5" width="38" height="17" rx="3.5" stroke="#F4F4F4" />
    <path
      d="M11.4291 10.228H12.6171C12.2331 12.148 10.7331 13.156 8.84906 13.156C6.35306 13.156 4.90106 11.272 4.90106 8.632C4.90106 5.944 6.49706 4.144 8.94506 4.144C10.7451 4.144 12.1851 5.212 12.5691 7.072H11.3811C11.1291 5.98 10.2651 5.176 8.88506 5.176C7.34906 5.176 6.14906 6.376 6.14906 8.632C6.14906 10.84 7.30106 12.124 8.92106 12.124C10.3251 12.124 11.1531 11.344 11.4291 10.228ZM15.6523 13V5.356H12.8203V4.3H19.7083V5.356H16.8763V13H15.6523ZM26.0469 13L24.1629 9.328C23.9349 9.34 23.7309 9.34 23.5029 9.34H21.9669V13H20.7429V4.3H23.5029C25.6149 4.3 27.1269 4.72 27.1269 6.808C27.1269 8.188 26.4669 8.848 25.3989 9.136L27.4149 13H26.0469ZM23.5989 5.332H21.9669V8.308H23.5989C24.8829 8.308 25.8669 8.176 25.8669 6.832C25.8669 5.488 24.8829 5.332 23.5989 5.332ZM30.1115 4.3V11.944H34.7075V13H28.8875V4.3H30.1115Z"
      fill="#929292"
      className={cx('group-hover:fill-t2-neutral-grey05', transitionAnimationClassNames)}
    />
    <rect
      x="41.5"
      y="0.5"
      width="17"
      height="17"
      rx="3.5"
      fill="#F4F4F4"
      className={cx('fill-t2-shades-white group-hover:fill-t2-neutral-grey02', transitionAnimationClassNames)}
    />
    <rect
      x="41.5"
      y="0.5"
      width="17"
      height="17"
      rx="3.5"
      stroke="#F4F4F4"
      className={cx('fill-t2-shades-white group-hover:fill-t2-neutral-grey02', transitionAnimationClassNames)}
    />
    <path
      d="M48.2599 4.3V8.68L52.1839 4.3H53.7079L50.4559 7.852L54.0799 13H52.6159L49.6279 8.752L48.2599 10.264V13H47.0359V4.3H48.2599Z"
      fill="#929292"
      className={cx('group-hover:fill-t2-neutral-grey05', transitionAnimationClassNames)}
    />
  </svg>
);
