import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import { TCloudinaryImageShape } from '../../CloudinaryResponsiveImage/types';
import { AnalyticsNotificationType, NotificationType } from '../constants';
import getUserDisplayName from '../../../../utils/getUserDisplayName';
import { TNotificationCardPropsProviderProps } from '../types';
import { enableGlobalScrollbar } from '../../Modal/utils/enableGlobalScrollbar';

export const getCommonPropsForPropsProvider = ({
  notification,
  sendEvent,
  closeAsidePanel,
}: TNotificationCardPropsProviderProps): {
  createdAt: string;
  onClick: () => void;
  initiatorName: string;
  profileImage: TCloudinaryImageShape;
} => {
  const { createdAt: createdAtRaw, initiator } = notification;
  const type = notification?.type as unknown as NotificationType;
  const { name, username } = initiator || {};
  const profileImage = initiator?.profileImage as TCloudinaryImageShape;
  const createdAt = formatDistanceToNowStrict(parseISO(createdAtRaw), { addSuffix: true });
  const initiatorName = getUserDisplayName({ name, username });
  const onClick = () => {
    enableGlobalScrollbar();
    closeAsidePanel();
    sendEvent('notification_click', { notification_type: AnalyticsNotificationType[type] });
  };

  return {
    createdAt,
    onClick,
    initiatorName,
    profileImage,
  };
};
