import { FC } from 'react';

export const LikeBadgeIcon: FC = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1566 3.18916C11.8586 2.89108 11.5049 2.65462 11.1155 2.4933C10.7262 2.33197 10.3089 2.24893 9.88741 2.24893C9.46596 2.24893 9.04863 2.33197 8.65928 2.4933C8.26993 2.65462 7.91618 2.89108 7.61824 3.18916L6.99991 3.8075L6.38157 3.18916C5.77975 2.58734 4.96351 2.24924 4.11241 2.24924C3.2613 2.24924 2.44506 2.58734 1.84324 3.18916C1.24142 3.79098 0.90332 4.60723 0.90332 5.45833C0.90332 6.30943 1.24142 7.12568 1.84324 7.7275L2.46157 8.34583L6.99991 12.8842L11.5382 8.34583L12.1566 7.7275C12.4547 7.42956 12.6911 7.07581 12.8524 6.68645C13.0138 6.2971 13.0968 5.87978 13.0968 5.45833C13.0968 5.03688 13.0138 4.61956 12.8524 4.23021C12.6911 3.84086 12.4547 3.4871 12.1566 3.18916V3.18916Z"
      fill="#F85E4A"
      stroke="#E9E9E9"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
