import React, { ReactNode, MouseEvent } from 'react';
import cx from 'classnames';
import Link from 'next/link';

type TDropdownClickableItemProps = {
  onClick?: (e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>) => void;
  children: ReactNode;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  className?: string;
  disabled?: boolean;
  type?: 'link' | 'button';
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
};

const DropdownClickableItem: React.FC<TDropdownClickableItemProps> = ({
  onClick,
  leftAdornment,
  rightAdornment,
  children,
  className,
  disabled,
  target,
  type = 'button',
  href = '',
}) => {
  const Tag = type === 'button' ? 'button' : Link;
  return (
    <Tag
      disabled={disabled}
      className={cx(
        'py-3 px-4 flex items-center w-full hover:bg-gray-100',
        {
          'text-gray-100 opacity-25': disabled,
        },
        className,
      )}
      onClick={onClick}
      href={href}
      target={target}
    >
      {leftAdornment && <div className="w-4 h-4 md:w-6 md:h-6 mr-2 stroke-black-700">{leftAdornment}</div>}
      <div className="grow font-secondary text-xs md:text-base text-black-700 flex items-center">{children}</div>
      {rightAdornment && <div className="w-4 h-4 md:w-6 md:h-6 ml-2">{rightAdornment}</div>}
    </Tag>
  );
};
export default DropdownClickableItem;
