import React, { FC, CSSProperties, ReactNode } from 'react';
import cx from 'classnames';

type TBackgroundColorVariant = 'darkGreen' | 'lightGreen' | 'neutralGrey01' | 'ghost';
type TSizeVariant = 'small' | 'medium' | 'large';
type TTextColorVariant = 'primary' | 'secondary';

type TLabelProps = {
  children: ReactNode;
  backgroundColor?: TBackgroundColorVariant;
  textColor?: TTextColorVariant;
  size?: TSizeVariant;
  className?: string;
  style?: CSSProperties;
};

const backgroundColorVariant: Record<TBackgroundColorVariant, string> = {
  darkGreen: 'bg-t2-success-darkGreen',
  lightGreen: 'bg-t2-success-lightGreen',
  neutralGrey01: 'bg-t2-neutral-grey01',
  ghost: 'bg-transparent',
};

const textColorVariant: Record<TTextColorVariant, string> = {
  primary: 'text-black-700',
  secondary: 'text-white-900',
};

const sizeVariant: Record<TSizeVariant, string> = {
  small: 'h-[30px]',
  medium: 'h-8',
  large: 'h-8 md:h-10',
};

const Label: FC<TLabelProps> = ({
  children,
  backgroundColor = 'darkGreen',
  textColor = 'primary',
  size = 'small',
  className,
  style,
}) => (
  <div
    className={cx(
      'flex items-center w-fit px-4 py-1 text-sm font-secondary rounded-2xl whitespace-nowrap',
      backgroundColorVariant[backgroundColor],
      textColorVariant[textColor],
      sizeVariant[size],
      className,
    )}
    style={style}
  >
    {children}
  </div>
);

export default Label;
