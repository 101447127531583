import { FC } from 'react';

export const ReplyBadgeIcon: FC = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.41259 0.5H10.7273C11.7833 0.5 12.6399 1.35611 12.6399 2.41242V14.0876C12.6399 15.1437 11.7833 16 10.7273 16H6.56994H2.41259C1.3566 16 0.5 15.1437 0.5 14.0876V2.41242C0.5 1.35609 1.35642 0.5 2.41259 0.5Z"
      fill="#D97706"
      stroke="#E9E9E9"
    />
    <rect x="5" y="4" width="3.80535" height="1.03782" rx="0.518911" fill="#F9F9F9" />
    <rect x="2" y="6" width="9.34041" height="1.12431" rx="0.562154" fill="#F9F9F9" />
    <rect x="2" y="8" width="9.34041" height="1.12431" rx="0.562154" fill="#F9F9F9" />
    <rect x="2" y="10" width="9.34041" height="1.12431" rx="0.562154" fill="#F9F9F9" />
    <rect x="2" y="12" width="9.34041" height="1.12431" rx="0.562154" fill="#F9F9F9" />
  </svg>
);
