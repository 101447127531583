import { TCloudinaryImageShape } from '../../CloudinaryResponsiveImage/types';
import { NotificationCardTitleWithInitiator } from '../NotificationCardTitleWithInitiator';
import { NotificationProfileImageWithBadge } from '../NotificationProfileImageWithBadge';
import { TNotificationCardPropsProvider } from '../types';
import { getCommonPropsForPropsProvider } from './getCommonPropsForPropsProvider';
import { ReplyBadgeIcon } from '../ReplyBadgeIcon';
import { NotificationCardContentImage } from '../NotificationCardContentImage';
import { getArticlePageUrl } from '../../../services/pagesUrl/getArticlePageUrl';

export const newQuoteReplyToPostProvider: TNotificationCardPropsProvider = ({
  notification,
  sendEvent,
  closeAsidePanel,
}) => {
  const { createdAt, onClick, initiatorName, profileImage } = getCommonPropsForPropsProvider({
    notification,
    sendEvent,
    closeAsidePanel,
  });

  const { relatedArticle } = notification;

  return {
    createdAt,
    onClick,
    title: (
      <NotificationCardTitleWithInitiator initiatorName={initiatorName} content="replied to your quote with a post" />
    ),

    content: relatedArticle?.parentArticle?.body?.blocks?.[0]?.data?.text as string,
    leftAdornment: (
      <NotificationProfileImageWithBadge image={profileImage as TCloudinaryImageShape} badgeIcon={<ReplyBadgeIcon />} />
    ),
    url: `${getArticlePageUrl(relatedArticle?.slug as string)}`,
    rightAdornment: <NotificationCardContentImage image={relatedArticle?.coverImage as TCloudinaryImageShape} />,
  };
};
