import React, { FC } from 'react';

type TFullScreenIconProps = {
  className?: string;
};

const FullScreenIcon: FC<TFullScreenIconProps> = ({ className }) => (
  <svg width="25" height="25" className={className} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.24045 3.5H5.15008C4.60367 3.5 4.07964 3.71071 3.69327 4.08579C3.3069 4.46086 3.08984 4.96957 3.08984 5.5V8.5M21.632 8.5V5.5C21.632 4.96957 21.415 4.46086 21.0286 4.08579C20.6422 3.71071 20.1182 3.5 19.5718 3.5H16.4814M16.4814 21.5H19.5718C20.1182 21.5 20.6422 21.2893 21.0286 20.9142C21.415 20.5391 21.632 20.0304 21.632 19.5V16.5M3.08984 16.5V19.5C3.08984 20.0304 3.3069 20.5391 3.69327 20.9142C4.07964 21.2893 4.60367 21.5 5.15008 21.5H8.24045"
      stroke=""
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FullScreenIcon;
