'use client';

import React, { FC, useRef } from 'react';
import { useRouter as PagesUseRouter } from 'next/compat/router';
import { useRouter as AppUseRouter, usePathname } from 'next/navigation';
import { UseFormReturn } from 'react-hook-form';
import { TextInput } from 'flowbite-react';
import { Form } from '../Form/Form';
import { TSearchFormField } from './types';
import { SearchIcon } from '../Icons/SearchIcon';
import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { getSignInPageUrl } from '../../services/pagesUrl/getSignInPageUrl';
import { getSearchPageUrl } from '../../services/pagesUrl/getSearchPageUrl';
import { MAX_SEARCH_LENGTH } from './constants';
import { whitespacesValidator } from '../Form/validators/whitespacesValidator';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

type TSearchFormProps = {
  useFormReturn: UseFormReturn<TSearchFormField>;
  closeModal: () => void;
};

export const SearchForm: FC<TSearchFormProps> = ({ useFormReturn, closeModal }) => {
  const { register, handleSubmit } = useFormReturn;
  const pathname = usePathname();
  const router = pathname !== null ? AppUseRouter() : PagesUseRouter();
  const { isAuthenticated } = useReactiveAuthenticatedUser();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { sendEvent } = useAnalytics();

  const { ref, ...rest } = register('search', {
    validate: {
      whitespaces: whitespacesValidator,
    },
  });

  const onSubmit = async ({ search }: { search: string }) => {
    sendEvent('search_form_submit', {
      search_term: search,
    });
    const encodedSearchTerm = encodeURIComponent(search.trim());

    try {
      if (!isAuthenticated) {
        return router?.push(getSignInPageUrl(`/search?term=${encodedSearchTerm}`));
      }

      router?.push(getSearchPageUrl(encodedSearchTerm));
      closeModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      closeModal();
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        ref={(node) => {
          ref(node);
          inputRef.current = node;
        }}
        {...rest}
        id="search"
        color="t2-search"
        type="text"
        inputMode="search"
        icon={SearchIcon}
        placeholder="What would you like to search?"
        maxLength={MAX_SEARCH_LENGTH}
        autoFocus
      />
    </Form>
  );
};
