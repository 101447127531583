import { useEffect, useState } from 'react';

export const useFullScreenMode = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const enableFullScreen = () => {
    if (document.fullscreenElement === null) {
      document.documentElement.requestFullscreen();
    }
  };
  const disableFullScreen = () => {
    if (document.fullscreenElement != null) {
      document.exitFullscreen();
    }
  };

  const onFullScreenChange = () => {
    setIsFullScreen(Boolean(document.fullscreenElement));
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullScreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullScreenChange);
  }, []);

  return { enableFullScreen, disableFullScreen, isFullScreen };
};
