import React from 'react';
import cx from 'classnames';
import { transitionAnimationClassNames } from '../../../utils/transitionAnimationClassNames';
import LinkButton from '../LinkButton/LinkButton';
import IconButton from '../IconButton/IconButton';
import { BlockForDeactivatedUser } from '../BlockForDeactivatedUser/BlockForDeactivatedUser';
import { getNewArticlePageUrl } from '../../services/pagesUrl/getNewArticlePageUrl';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

const NavbarNewArticlePostLink = () => {
  const { sendEvent } = useAnalytics();
  const handleClick = () => sendEvent('navbar_new_post_button_click');

  return (
    <>
      <BlockForDeactivatedUser>
        <LinkButton
          href={getNewArticlePageUrl()}
          className={cx(
            'hidden md:flex before:content-plus before:mr-2 before:h-[20px]',
            transitionAnimationClassNames,
          )}
          variant="secondary"
          onClick={handleClick}
        >
          New post
        </LinkButton>
      </BlockForDeactivatedUser>
      <BlockForDeactivatedUser>
        <IconButton
          tag="a"
          href={getNewArticlePageUrl()}
          color="secondary"
          className="md:hidden before:content-plus before:h-[20px]"
          onClick={handleClick}
        />
      </BlockForDeactivatedUser>
    </>
  );
};

export default NavbarNewArticlePostLink;
