import React, { FC } from 'react';
import { Notification } from 't2-graphql-types';
import { NotificationType } from './constants';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { getPropsProviderForNotificationCard } from './services/getPropsProviderForNotificationCard';
import { NotificationCard } from './NotificationCard';
import { useAsidePanelContext } from '../AsidePanel/contexts/AsidePanelContext';

type TNotificationCardContainer = {
  notification: Notification;
};

export const NotificationCardContainer: FC<TNotificationCardContainer> = ({ notification }) => {
  const { sendEvent } = useAnalytics();
  const { closeAsidePanel } = useAsidePanelContext();
  const propsProvider = getPropsProviderForNotificationCard(notification!.type as unknown as NotificationType);

  if (!propsProvider) {
    return null;
  }

  return <NotificationCard {...propsProvider({ notification, sendEvent, closeAsidePanel })} />;
};
