import { ReactNode } from 'react';

export enum EBannerType {
  LENS_INTEGRATION_BANNER = 'LENS_INTEGRATION_BANNER',
  LENS_CAMPAIGN_BANNER = 'LENS_CAMPAIGN_BANNER',
  THP_V2_UPDATE_BANNER = 'THP_V2_UPDATE_BANNER',
}

export type TShowBannerHandler = (data: { type: EBannerType; content: ReactNode; onClose?: () => void }) => void;

export type TBannerContextProps = {
  showBanner: TShowBannerHandler;
  closeBanner: () => void;
  type: EBannerType | null;
};

export type TBannerContextProviderProps = {
  content?: ReactNode;
  children: ReactNode;
};
