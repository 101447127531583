import { APP_URL } from '../config';

export const isInternalUrl = (url: string | undefined) => {
  if (url?.startsWith('/')) {
    return true;
  }
  if (url?.startsWith(APP_URL)) {
    return true;
  }

  return false;
};
