import React from 'react';
import { getCommonPropsForPropsProvider } from './getCommonPropsForPropsProvider';
import { NotificationCardTitleWithInitiator } from '../NotificationCardTitleWithInitiator';
import { NotificationProfileImageWithBadge } from '../NotificationProfileImageWithBadge';
import { TCloudinaryImageShape } from '../../CloudinaryResponsiveImage/types';
import { TNotificationCardPropsProvider } from '../types';
import { getArticlePageUrl } from '../../../services/pagesUrl/getArticlePageUrl';
import { NotificationCardContentImage } from '../NotificationCardContentImage';
import { CollectTransactionStatusType } from '../../Collect/constants';
import { CollectBadgeIcon } from '../CollectBadgeIcon';
import { ArrowIcon } from '../ArrowIcon';
import { NotificationKind } from '../constants';

const getNotificationContent = (
  authorId: string,
  recipientId: string,
  transactionStatus: CollectTransactionStatusType | null | undefined,
): string => {
  if (transactionStatus === CollectTransactionStatusType.Executed) {
    if (authorId === recipientId) {
      return 'has just collected your post';
    }

    return 'You have just collected a post';
  }

  if (transactionStatus === CollectTransactionStatusType.Failed) {
    return 'NFT post purchase unsuccessful. Go back to the post page and try again.';
  }

  return '';
};

const getInitiatorName = (authorId: string, recipientId: string, initiatorName: string): string => {
  if (authorId === recipientId) {
    return initiatorName;
  }

  return '';
};

export const newCollectTransactionStatusPropsProvider: TNotificationCardPropsProvider = ({
  notification,
  sendEvent,
  closeAsidePanel,
}) => {
  const { createdAt, onClick, initiatorName, profileImage } = getCommonPropsForPropsProvider({
    notification,
    sendEvent,
    closeAsidePanel,
  });

  const { relatedArticle, relatedCollect } = notification;

  return {
    createdAt,
    onClick,
    title: (
      <NotificationCardTitleWithInitiator
        initiatorName={getInitiatorName(
          relatedArticle?.author?.id as unknown as string,
          notification?.recipient?.id as unknown as string,
          initiatorName,
        )}
        content={getNotificationContent(
          relatedArticle?.author?.id as unknown as string,
          notification?.recipient?.id as unknown as string,
          relatedCollect?.transactionStatus,
        )}
        notificationKind={
          relatedCollect?.transactionStatus === CollectTransactionStatusType.Failed
            ? NotificationKind.Errror
            : NotificationKind.Default
        }
      />
    ),
    content: relatedArticle?.title,
    leftAdornment: (
      <NotificationProfileImageWithBadge
        image={
          relatedArticle?.author?.id === notification?.recipient?.id
            ? (profileImage as TCloudinaryImageShape)
            : (relatedArticle?.author?.profileImage as TCloudinaryImageShape)
        }
        badgeIcon={<CollectBadgeIcon />}
      />
    ),
    url: `${getArticlePageUrl(relatedArticle?.slug as string)}`,
    rightAdornment:
      relatedCollect?.transactionStatus === CollectTransactionStatusType.Executed ? (
        <NotificationCardContentImage image={relatedArticle?.coverImage as TCloudinaryImageShape} />
      ) : (
        <ArrowIcon />
      ),
  };
};
