import { gql } from '@apollo/client';

export const connectUserWithLensMutation = gql`
  mutation connectUserWithLensMutation($id: ID!) {
    updateUser(where: { id: $id }, data: { isConnectedWithLens: true }) {
      id
      isConnectedWithLens
    }
  }
`;
