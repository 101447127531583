import { gql } from '@apollo/client';

export const getLensDefaultProfileByAddressQuery = gql`
  query getDefaultProfileByAddress($address: EvmAddress!) {
    defaultProfile(request: { for: $address }) {
      __typename
      id
      txHash
      createdAt
      interests
      invitesLeft
      handle {
        fullHandle
        localName
      }
      sponsor
      ownedBy {
        address
        chainId
      }
      operations {
        __typename
        id
        canBlock
        canUnblock
        canFollow
        canUnfollow
        isBlockedByMe {
          isFinalisedOnchain
          value
        }
        isFollowedByMe {
          isFinalisedOnchain
          value
        }
        isFollowingMe {
          isFinalisedOnchain
          value
        }
      }
      guardian {
        protected
        cooldownEndsOn
      }
      onchainIdentity {
        proofOfHumanity
        ens {
          name
        }
        sybilDotOrg {
          source {
            twitter {
              handle
            }
          }
        }
        worldcoin {
          isHuman
        }
      }
      followNftAddress {
        __typename
        address
        chainId
      }
      followModule {
        __typename
      }
      metadata {
        appId
        displayName
        bio
        rawURI
        picture {
          __typename
        }
        coverPicture {
          raw {
            uri
          }
          optimized {
            uri
          }
        }
      }
      invitedBy {
        id
      }
      stats {
        followers
        followers
        following
        reacted
        reactions
        publications
      }
    }
  }
`;
