import { FC } from 'react';
import cx from 'classnames';

type TSparksIconProps = {
  className?: string;
  stroke?: string;
};

export const SparksIcon: FC<TSparksIconProps> = ({ className, stroke = '#0BD89F' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx(className)}
  >
    <path
      d="M6.66669 12.5C10.7289 12.5 12.5 10.7908 12.5 6.66666C12.5 10.7908 14.2587 12.5 18.3334 12.5C14.2587 12.5 12.5 14.2587 12.5 18.3333C12.5 14.2587 10.7289 12.5 6.66669 12.5Z"
      stroke={stroke}
      strokeLinejoin="round"
    />
    <path
      d="M1.66669 5.41666C4.27815 5.41666 5.41669 4.31794 5.41669 1.66666C5.41669 4.31794 6.54726 5.41666 9.16669 5.41666C6.54726 5.41666 5.41669 6.54724 5.41669 9.16666C5.41669 6.54724 4.27815 5.41666 1.66669 5.41666Z"
      stroke={stroke}
      strokeLinejoin="round"
    />
  </svg>
);
