import { useMemo } from 'react';
import { useAuthenticatedUser } from '../../Auth/hooks/useAuthenticatedUser';
import { TUserTerritoryPermissions } from '../types';
import { useHasPermission } from '../../Permissions/hooks/useHasPermission';
import { getUserActiveRoles } from '../../Permissions/services/getUserActiveRoles';

type TUseUserTerritoryPermissions = {
  territoryId?: string;
};

export const useUserTerritoryPermissions = ({
  territoryId,
}: TUseUserTerritoryPermissions): TUserTerritoryPermissions => {
  const { data, loading, refetch } = useAuthenticatedUser();
  const hasPermission = useHasPermission();
  const roles = getUserActiveRoles(data?.user);
  const canUpdateTerritoryGlobally = hasPermission('GLOBAL_CAN_UPDATE_TERRITORY');
  const canUpdateTerritory =
    !!(territoryId && hasPermission('TERRITORY_CAN_UPDATE_TERRITORY', territoryId)) || canUpdateTerritoryGlobally;
  const isTerritoryMember = !!roles.find((role) => role?.territory?.id === territoryId);
  const isTerritoryAdmin = canUpdateTerritory;

  return useMemo(
    () => ({
      isTerritoryMember,
      loading,
      canUpdateTerritory,
      isTerritoryAdmin,
      canUpdateTerritoryGlobally,
      refetchUserData: () => refetch?.(),
    }),
    [isTerritoryMember, loading, canUpdateTerritory, isTerritoryAdmin, canUpdateTerritoryGlobally, refetch],
  );
};
