import { Notification } from 't2-graphql-types';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import React, { useLayoutEffect, useEffect, useRef } from 'react';
import Typo from '../Typography';
import Spinner from '../Spinner/Spinner';
import { NotificationCardContainer } from './NotificationCardContainer';
import { NOTIFICATIONS_INITIAL_ITEMS_COUNT } from './constants';
import { isBrowser } from '../../services/browser/isBrowser';

type TNotificationsListProps = {
  notifications: Notification[];
  notificationsCount: number;
  loading: boolean;
  fetchMoreNotifications: () => void;
  isOpen: boolean;
};

const Loader = (
  <div className="my-5 text-center">
    <Spinner size={8} />
  </div>
);

const Footer = ({
  context: { hasMoreNotificationsToLoad, fetchMoreNotifications } = {
    hasMoreNotificationsToLoad: true,
    fetchMoreNotifications: () => {},
  },
}) => {
  if (!hasMoreNotificationsToLoad) {
    return null;
  }

  return (
    <button onClick={fetchMoreNotifications} className="block mx-auto py-11">
      <Typo className="!text-[14px] hover:underline">Load more</Typo>
    </button>
  );
};

const useIsomorphicLayoutEffect = isBrowser() ? useLayoutEffect : useEffect;

export const NotificationsList = ({
  notifications,
  notificationsCount,
  loading,
  fetchMoreNotifications,
  isOpen,
}: TNotificationsListProps) => {
  const hasMoreNotificationsToLoad = notificationsCount > notifications?.length;
  const title = (
    <Typo tag="div" className="flex-1 px-7 text-[20px] md:text-[22px] font-bold mt-10 mb-3">
      Notifications
    </Typo>
  );
  const virtuosoGridRef = useRef<null | VirtuosoHandle>(null);

  useIsomorphicLayoutEffect(() => {
    if (isOpen) {
      virtuosoGridRef?.current?.scrollTo({ top: 0, behavior: 'instant' });
    }
  }, [isOpen]);

  if (loading && notificationsCount === 0) {
    return Loader;
  }

  if (!loading && notificationsCount === 0) {
    return (
      <div className="flex flex-col w-full">
        {title}
        <div className="flex flex-col place-content-center h-full bottom-[50px] text-center text-black-700">
          <Typo tag="div" className="py-3.5 text-[16px] font-bold leading-6">
            You’re all caught up!
          </Typo>
          <Typo tag="div" className="text-[14px] leading-[22px] text-[#bdbdbd]">
            We'll let you know when there’s something new.
          </Typo>
        </div>
      </div>
    );
  }

  const renderItemContent = (_index: number, notification?: Notification) => {
    if (!notification) {
      return null;
    }

    return <NotificationCardContainer notification={notification} />;
  };

  return (
    <div className="flex flex-col h-full w-full">
      {title}
      <Virtuoso
        ref={virtuosoGridRef}
        initialItemCount={NOTIFICATIONS_INITIAL_ITEMS_COUNT}
        context={{ hasMoreNotificationsToLoad, fetchMoreNotifications }}
        data={notifications}
        totalCount={notificationsCount}
        itemContent={renderItemContent}
        components={{ Footer }}
      />
    </div>
  );
};
