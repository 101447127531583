import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import CloudinaryResponsiveImage from '../CloudinaryResponsiveImage/CloudinaryResponsiveImage';

type TNotificationCardContentImageProps = {
  image?: TCloudinaryImageShape;
};

export const NotificationCardContentImage = ({ image }: TNotificationCardContentImageProps) =>
  image ? (
    <div>
      <CloudinaryResponsiveImage
        containerClassName="h-10 w-10 hidden md:block"
        className="h-10 w-10 object-cover rounded-sm"
        alt="notification image"
        image={image}
      />
    </div>
  ) : null;
