import React from 'react';
import { getCommonPropsForPropsProvider } from './getCommonPropsForPropsProvider';
import { NotificationCardTitleWithInitiator } from '../NotificationCardTitleWithInitiator';
import { NotificationProfileImageWithBadge } from '../NotificationProfileImageWithBadge';
import { TCloudinaryImageShape } from '../../CloudinaryResponsiveImage/types';
import { TNotificationCardPropsProvider } from '../types';
import { ReplyBadgeIcon } from '../ReplyBadgeIcon';
import { getArticlePageUrl } from '../../../services/pagesUrl/getArticlePageUrl';
import { NotificationCardContentImage } from '../NotificationCardContentImage';

export const newPostInTerritoryPropsProvider: TNotificationCardPropsProvider = ({
  notification,
  sendEvent,
  closeAsidePanel,
}) => {
  const { createdAt, onClick, initiatorName, profileImage } = getCommonPropsForPropsProvider({
    notification,
    sendEvent,
    closeAsidePanel,
  });

  const { relatedArticle } = notification;

  return {
    createdAt,
    onClick,
    title: (
      <NotificationCardTitleWithInitiator
        initiatorName={initiatorName}
        content={`added a new post to t/${relatedArticle?.territory?.slug}`}
      />
    ),
    content: relatedArticle?.title,
    leftAdornment: (
      <NotificationProfileImageWithBadge image={profileImage as TCloudinaryImageShape} badgeIcon={<ReplyBadgeIcon />} />
    ),
    url: `${getArticlePageUrl(relatedArticle?.slug as string)}`,
    rightAdornment: <NotificationCardContentImage image={relatedArticle?.coverImage as TCloudinaryImageShape} />,
  };
};
