import { useLazyQuery } from '@apollo/client';
import { User } from '@privy-io/react-auth';
import Cookies from 'js-cookie';
import { EBannerType } from '../../Banner/types';
import { useBannerContext } from '../../Banner/BannerContext';
import { hasPermissionFactory } from '../../Permissions/hooks/useHasPermission';
import { ELensIntegrationBannerCookieValue } from '../types';
import { LENS_INTEGRATION_DISMISSED_BANNER_COOKIE_PREFIX } from '../constants';
import { LensIntegrationBannerContent } from '../LensIntegrationBannerContent';
import { useLazyDefaultProfile } from '../../../services/lens/hooks/useLazyDefaultProfile';
import { getAuthenticatedItemQuery } from '../../Auth/queries/getAuthenticatedItemQuery';

export const getLensSettingsPageUrl = (): string => '/settings/lens';

const getLensBannerCookieName = (address: string) => `${LENS_INTEGRATION_DISMISSED_BANNER_COOKIE_PREFIX}_${address}`;

const setHasDismissedBanner = (address: string) => {
  Cookies.set(getLensBannerCookieName(address), ELensIntegrationBannerCookieValue.true, { expires: 365 });
};

const getHasDismissedBanner = (address: string): boolean =>
  Cookies.get(getLensBannerCookieName(address)) === ELensIntegrationBannerCookieValue.true;

export const useLensIntegrationBanner = () => {
  const { execute: getDefaultProfile } = useLazyDefaultProfile();
  const { showBanner, closeBanner } = useBannerContext();
  const [getAuthenticatedItem] = useLazyQuery(getAuthenticatedItemQuery, {
    fetchPolicy: 'no-cache',
  });

  const showLensIntegrationBanner = async (user: User) => {
    const walletAddress = user?.wallet?.address;
    if (!walletAddress) {
      return;
    }

    const hasDismissedLensBanner = getHasDismissedBanner(walletAddress);
    if (hasDismissedLensBanner) {
      return;
    }

    const data = await getAuthenticatedItem();
    const canAccessApp = hasPermissionFactory(data)('GLOBAL_CAN_ACCESS_APP');
    if (!canAccessApp) {
      return;
    }

    const hasLensProfile = !!(await getDefaultProfile(walletAddress));
    if (!hasLensProfile) {
      return;
    }

    showBanner({
      type: EBannerType.LENS_INTEGRATION_BANNER,
      content: <LensIntegrationBannerContent onRedirectLinkClick={() => setHasDismissedBanner(walletAddress)} />,
      onClose: () => setHasDismissedBanner(walletAddress),
    });
  };

  const hideLensIntegrationBanner = () => {
    closeBanner();
  };

  return { showLensIntegrationBanner, hideLensIntegrationBanner };
};
