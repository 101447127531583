import { cloneElement, FC, ReactElement } from 'react';
import { useProtectWithLogin } from './hooks/useProtectWithLogin';

type TProtectWithLoginProps = {
  children: ReactElement;
  redirectUrl?: string;
};

const ProtectWithLogin: FC<TProtectWithLoginProps> = ({ children, redirectUrl, ...props }) => {
  const { shouldProtect, redirect } = useProtectWithLogin(redirectUrl);
  const childrenIsOfTypeLink = children?.props.target === '_blank';

  return cloneElement(children, {
    ...props,
    ...(shouldProtect ? { onClick: redirect } : {}),
    ...(!shouldProtect && childrenIsOfTypeLink ? { href: redirectUrl } : {}),
  });
};

export default ProtectWithLogin;
