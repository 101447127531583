import { FC } from 'react';
import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import Typo from '../Typography/Typo';
import { SearchHistoryHeader } from './SearchHistoryHeader';
import { SearchHistoryList } from './SearchHistoryList';
import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { getSearchHistoryByUserId } from './queries/getSearchHistoryByUserId';

type TSearchFormHistoryProps = {
  closeModal: () => void;
};

export const SearchHistory: FC<TSearchFormHistoryProps> = ({ closeModal }) => {
  const { isAuthenticated, data } = useReactiveAuthenticatedUser();
  const userId = data?.user?.id;

  const {
    data: searchHistories,
    called: isCalled,
    loading: isLoading,
  } = useQuery(getSearchHistoryByUserId, {
    variables: {
      userId,
      take: 5,
    },
    skip: !userId,
  });

  if ((!isCalled && !isLoading) || isEmpty(searchHistories?.searchHistories) || !isAuthenticated) {
    return (
      <div className="flex h-[250px] mt-6 justify-center">
        <Typo variant="small-p" className="!text-xs text-t2-neutral-grey06">
          No recent search history
        </Typo>
      </div>
    );
  }

  return (
    <div className="mt-6 h-[250px]">
      <SearchHistoryHeader userId={data?.user?.id} />
      <SearchHistoryList searchHistories={searchHistories?.searchHistories} onItemClick={closeModal} />
    </div>
  );
};
