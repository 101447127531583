import { Profile, useLazyProfiles } from '@lens-protocol/react-web';
import { useState } from 'react';
import { fetchDefaultProfile } from '../api/fetchDefaultProfile';
import { resolveDefaultProfileStrategy } from '../utils/resolveDefaultProfileStrategy';

export const useLazyDefaultProfile = () => {
  const [data, setData] = useState<Profile | null>(null);
  const { execute: getProfiles } = useLazyProfiles();

  const getDefaultProfile = async (address: string) => {
    const { data: defaultProfile } = await fetchDefaultProfile({ address });
    const profilesRes = await getProfiles({ where: { ownedBy: [address] } });
    const profiles = profilesRes.unwrap();
    const response = resolveDefaultProfileStrategy({
      defaultProfile,
      allProfilesOwnedByAddress: profiles,
    }) as Profile | null;
    setData(response);

    return response;
  };

  return { execute: getDefaultProfile, data };
};
