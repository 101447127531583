import { useState, useEffect } from 'react';
import { UAParser } from 'ua-parser-js';
import kebabCase from 'lodash/kebabCase';
import { OperatingSystemType } from '../types';

export const useDetectOperatingSystem = () => {
  const [operatingSystem, setOperatingSystem] = useState<OperatingSystemType | null>(null);

  useEffect(() => {
    if (typeof window === 'object') {
      const parser = new UAParser();
      const result = parser.getResult();
      setOperatingSystem(kebabCase(result.os.name));
    }
  }, []);

  return operatingSystem;
};
