import { FC } from 'react';
import cx from 'classnames';

type TSearchIconProps = {
  className?: string;
};

export const SearchIcon: FC<TSearchIconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cx('stroke-t2-neutral-grey05 group-hover:stroke-t2-neutral-grey09', className)}
  >
    <path
      d="M7.33382 13.3581C10.2793 13.3581 12.6672 10.9703 12.6672 8.02474C12.6672 5.07922 10.2793 2.69141 7.33382 2.69141C4.3883 2.69141 2.00049 5.07922 2.00049 8.02474C2.00049 10.9703 4.3883 13.3581 7.33382 13.3581Z"
      stroke=""
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0006 14.6915L11.1006 11.7915"
      stroke=""
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
