import React, { FC } from 'react';
import cx from 'classnames';
import Image from 'next/image';
import { Territory } from 't2-graphql-types';
import DropdownLabel from '../Dropdown/DropdownLabel';
import Dropdown from '../Dropdown/Dropdown';
import NavbarTerritoriesDropdownItems from './NavbarTerritoriesDropdownItems';
import ChevronIcon from '../Navbar/ChevronIcon';
import IconButton from '../IconButton/IconButton';
import HamburgerMenuDark from '../../../public/images/hamburger-menu-dark.svg';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { TDropdownState } from '../Dropdown/types';
import { getGetAllTerritoriesNavbarInfo } from './queries/getGetAllTerritoriesNavbarInfo';
import { useT2Query } from '../../hooks/useT2Query';

type TNavbarTerritoriesDropdownProps = {};

type TTerritories = Pick<Territory, 'id' | 'name' | 'slug'>[];

const NavbarTerritoriesDropdown: FC<TNavbarTerritoriesDropdownProps> = () => {
  const { sendEvent } = useAnalytics();
  const { data, loading, error } = useT2Query<{ territories: TTerritories }>(getGetAllTerritoriesNavbarInfo);

  if (loading || error) {
    return null;
  }

  const handleStateChange = ({ isOpen }: TDropdownState) => {
    const territoryIds = data?.territories?.map((territory) => territory.id!) || [];
    const customParams = { territory_ids: territoryIds };
    if (isOpen) {
      sendEvent('navbar_territory_dropdown_open', customParams);
    } else {
      sendEvent('navbar_territory_dropdown_close', customParams);
    }
  };

  return (
    <Dropdown
      menuContainerClassName="max-h-[235px] overflow-y-scroll"
      onStateChange={handleStateChange}
      renderLabel={({ isOpen }) => (
        <>
          <DropdownLabel
            className="hidden md:flex"
            label="Territories"
            rightAdornment={
              <ChevronIcon
                className={cx('mr-1 ml-2 stroke-black-700', {
                  'scale-y-1 transition-all duration-200': !isOpen,
                  'scale-y-[-1] transition-all duration-200': isOpen,
                })}
                height={6}
                width={12}
              />
            }
            isActive={isOpen}
          />
          <IconButton tag="div" color="white" className="md:hidden">
            <Image tabIndex={-1} src={HamburgerMenuDark} alt="Territories menu" width={16} height={16} />
          </IconButton>
        </>
      )}
      menu={<NavbarTerritoriesDropdownItems territories={data?.territories || []} />}
    />
  );
};

export default NavbarTerritoriesDropdown;
