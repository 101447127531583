import { getSignInPageUrl } from 'src/services/pagesUrl/getSignInPageUrl';
import { useRouter } from 'next/compat/router';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import LinkButton from '../LinkButton/LinkButton';

export const NavbarLoginButton = () => {
  const router = useRouter();
  const { sendEvent } = useAnalytics();
  const currentPageUrl = router?.asPath;

  const onClick = () => {
    sendEvent('navbar_login_button_click');
  };

  return (
    <LinkButton onClick={onClick} href={getSignInPageUrl(currentPageUrl)} className="px-4 !py-1 md:!py-2">
      Log in / Join
    </LinkButton>
  );
};
