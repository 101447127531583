import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { useHasPermission } from '../Permissions/hooks/useHasPermission';
import NavbarUserMenu from './NavbarUserDropdown';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import { useNavbarModalContext } from '../NavbarModalComponent/context/NavbarModalContext';
import { NavbarLoginButton } from './NavbarLoginButton';

interface IAccountNavbarSection {
  showUserMenu?: boolean;
  showLoginButton?: boolean;
  refetchAuthenticatedItem: () => void;
}

export const NavbarAccountSection: React.FC<IAccountNavbarSection> = ({
  showUserMenu = false,
  showLoginButton,
  refetchAuthenticatedItem,
}) => {
  const userRequest = useReactiveAuthenticatedUser();
  const user = userRequest?.data?.user;

  const hasPermission = useHasPermission();
  const canAccessApp = hasPermission('GLOBAL_CAN_ACCESS_APP');
  const { handleOpenHelpOnboardingModal } = useNavbarModalContext();

  if (user) {
    return (
      <NavbarUserMenu
        canAccessApp={canAccessApp}
        showUserMenu={showUserMenu}
        username={user.username!}
        publicAddress={user.publicAddress!}
        onHelpClick={handleOpenHelpOnboardingModal}
        refetchUserDetails={refetchAuthenticatedItem}
        profileImage={user.profileImage as TCloudinaryImageShape}
        cumulativeTimePoints={user.cumulativeTimePoints!}
        name={user?.name || undefined}
        id={user.id}
      />
    );
  }

  if (showLoginButton) {
    return <NavbarLoginButton />;
  }

  return null;
};
