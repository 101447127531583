import { ApolloQueryResult } from '@apollo/client';
import { Profile } from '@lens-protocol/react-web';
import { lensApolloClient } from '../lensApolloClient';
import { getLensDefaultProfileByAddressQuery } from '../queries/getDefaultProfileByAddress';

type TFetchDefaultProfileProps = {
  address: string;
};

type TFetchDefaultProfileResponse = {
  defaultProfile: Profile | undefined;
};

type TFetchDefaultProfileReturnValue = ApolloQueryResult<Profile | undefined>;

// TODO: Replace with lens client
export const fetchDefaultProfile = async ({
  address,
}: TFetchDefaultProfileProps): Promise<TFetchDefaultProfileReturnValue> => {
  const response = await lensApolloClient.query<TFetchDefaultProfileResponse>({
    query: getLensDefaultProfileByAddressQuery,
    variables: {
      address,
    },
  });

  const { defaultProfile } = response.data;

  return { ...response, data: defaultProfile };
};
