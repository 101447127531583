import React, { FC } from 'react';
import { List } from 'flowbite-react';
import { SearchHistoryListItem } from './SearchHistoryListItem';
import { TSearchHistoryListProps } from './types';
import { getSearchPageUrl } from '../../services/pagesUrl/getSearchPageUrl';

export const SearchHistoryList: FC<TSearchHistoryListProps> = ({ searchHistories, onItemClick }) => (
  <List unstyled className="mt-4 gap-0">
    {searchHistories.map((item) => {
      const encodedSearchTerm = encodeURIComponent(item.search?.trim() as string);
      return (
        <SearchHistoryListItem
          key={item.id}
          id={item.id}
          searchTerm={item.search as string}
          url={getSearchPageUrl(encodedSearchTerm as string)}
          onItemClick={onItemClick}
        />
      );
    })}
  </List>
);
