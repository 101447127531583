import Image from 'next/image';
import cx from 'classnames';
import Link from 'next/link';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

const T2_LOGO_DARK_SRC = '/images/t2-logo-dark.svg';

export const NavbarLogoLink = ({ href, isDisabled }: { href: string; isDisabled: boolean }) => {
  const { sendEvent } = useAnalytics();
  const logo = T2_LOGO_DARK_SRC;
  const sendAnalyticsEvent = () => sendEvent('navbar_logo_click', { href });

  const logoImage = (
    <Image
      priority
      height="42"
      width="28"
      src={logo}
      alt="t2 logo"
      className={cx({
        'cursor-default pointer-events-none': isDisabled,
      })}
    />
  );

  return (
    <div className="h-[42px] w-[28px] hover:opacity-[0.8] cursor-pointer">
      <Link onClick={sendAnalyticsEvent} href={href}>
        {logoImage}
      </Link>
    </div>
  );
};
