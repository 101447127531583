import { gql } from '@apollo/client';

export const getSearchHistoryByUserId = gql`
  query getSearchHistoryByUserId($userId: ID!, $take: Int!) {
    searchHistories(where: { user: { id: { equals: $userId } } }, orderBy: { createdAt: desc }, take: $take) {
      id
      search
    }
  }
`;
