import { useRouter } from 'next/compat/router';
import { MouseEvent } from 'react';
import { useAuthenticatedUser } from '../../Auth/hooks/useAuthenticatedUser';
import { getSignInPageUrl } from '../../../services/pagesUrl/getSignInPageUrl';
import { useHasPermission } from '../../Permissions/hooks/useHasPermission';

export const useProtectWithLogin = (redirectUrl?: string) => {
  const router = useRouter();
  const { isAuthenticated, loading } = useAuthenticatedUser();
  const hasPermission = useHasPermission();

  const getRedirectUrl = () => {
    const currentPageUrl = router?.asPath;
    if (!isAuthenticated) {
      return getSignInPageUrl(redirectUrl || currentPageUrl);
    }
    return currentPageUrl;
  };

  const redirect = (e?: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    e?.preventDefault();
    router?.push(getRedirectUrl());
  };

  return {
    shouldProtect: !(isAuthenticated && hasPermission('GLOBAL_CAN_ACCESS_APP')),
    loading,
    redirect,
  };
};
