import { ReactNode } from 'react';
import Typo from '../Typography';
import { NotificationKind } from './constants';

type TNotificationTitleProps = {
  initiatorName: string;
  content: ReactNode;
  notificationKind?: NotificationKind;
};

export const NotificationCardTitleWithInitiator = ({
  initiatorName,
  content,
  notificationKind,
}: TNotificationTitleProps) => (
  <Typo className="text-[12px] md:text-[14px]">
    <span className="font-bold">{initiatorName} </span>
    {notificationKind === NotificationKind.Error && (
      <>
        ❗️️ <span className="text-t2-error-darkRed">Error: </span>
      </>
    )}
    {content}
  </Typo>
);
