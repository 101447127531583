import { LensIcon } from 'src/components/Icons/LensIcon';
import cx from 'classnames';
import { NotificationSuccessIcon } from '../NotificationSuccessIcon/NotificationSuccessIcon';
import { useConnectWithLens } from '../ConnectWithLensCard/hooks/useConnectWithLens';
import DropdownClickableItem from '../Dropdown/DropdownClickableItem';
import { getLensShareProfileUrl } from '../../services/lens/utils/getLensShareProfileUrl';
import { getLensDisplayNameByProfile } from '../../services/lens/utils/getLensDisplayNameByProfile';
import { useActiveLensProfile } from '../../services/lens/hooks/useActiveLensProfile';
import Spinner from '../Spinner/Spinner';
import { colors } from '../Spinner/utils/colors';
import { useAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';

const LensIntegrationAndornment = (
  <div className="bg-green-600 rounded-full w-4 h-4 md:h-6 md:w-6 flex items-center justify-center">
    <NotificationSuccessIcon className="w-3 h-3 md:h-4 md:w-4" color="white" />
  </div>
);

export const NavbarLensIntegrationDropdownItem = () => {
  const { refetch: refetchUserDetails } = useAuthenticatedUser();
  const { connectWithLens, isLoading } = useConnectWithLens();
  const { data: profile } = useActiveLensProfile();
  const lensDisplayName = getLensDisplayNameByProfile(profile);
  const lensProfileUrl = getLensShareProfileUrl(profile?.id);

  if (lensDisplayName) {
    return (
      <DropdownClickableItem
        leftAdornment={<LensIcon className="w-4 h-4 md:w-6 md:h-6 fill-lens-primary" />}
        rightAdornment={LensIntegrationAndornment}
        href={lensProfileUrl}
        target="_blank"
        type="link"
      >
        <div title={lensDisplayName} className="text-lens-primary text-ellipsis overflow-hidden max-w-[100px]">
          {lensDisplayName}
        </div>
      </DropdownClickableItem>
    );
  }

  return (
    <DropdownClickableItem
      className="text-green-600"
      onClick={async (e) => {
        e.stopPropagation();
        await connectWithLens();
        await refetchUserDetails();
      }}
      disabled={isLoading}
      leftAdornment={<LensIcon className="w-4 h-4 md:w-6 md:h-6 fill-green-600" />}
    >
      <div className={cx('text-green-600', { invisible: isLoading })}>Connect with Lens</div>
      {isLoading && (
        <div className="absolute left-1/2 -translate-x-1/2">
          <Spinner size={8} color={colors.green?.[600]} />
        </div>
      )}
    </DropdownClickableItem>
  );
};
