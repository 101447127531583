import { List } from 'flowbite-react';
import Link from 'next/link';
import React, { FC } from 'react';
import { useMutation } from '@apollo/client';
import { SearchIcon } from '../Icons/SearchIcon';
import Typo from '../Typography/Typo';
import Cross from '../NotificationBanner/Cross';
import { deleteSearchHistory } from './mutations/deleteSearchHistory';
import Spinner from '../Spinner/Spinner';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { getMultilineEllipsisStyle } from '../PromptCard/utils/getMultilineEllipsisStyle';

type TRemoveSearchHistoryEntryButtonProps = {
  onRemoveButtonClick: () => void;
};

const RemoveSearchHistoryEntryButton: FC<TRemoveSearchHistoryEntryButtonProps> = ({ onRemoveButtonClick }) => (
  <button onClick={onRemoveButtonClick} className="lg:hidden lg:group-hover:block relative ml-auto z-20">
    <Cross color="#131313" className="h-4 w-4" />
  </button>
);

type TSearchHistoryListItem = {
  id: string;
  searchTerm: string;
  url: string;
  onItemClick: () => void;
};

export const SearchHistoryListItem: FC<TSearchHistoryListItem> = ({ id, searchTerm, url, onItemClick }) => {
  const [deleteSearchTerm, { loading: isLoading, called: isCalled }] = useMutation(deleteSearchHistory, {
    variables: {
      id,
    },
    refetchQueries: ['getSearchHistoryByUserId'],
  });
  const { sendEvent } = useAnalytics();

  const handleRemoveButtonClick = async () => {
    sendEvent('search_history_remove_single_item_click');
    await deleteSearchTerm();
  };

  const onClick = () => {
    sendEvent('search_history_item_click', {
      search_term: searchTerm,
    });
    onItemClick();
  };

  if (isLoading || isCalled) {
    return (
      <List.Item className="flex !mt-0 h-[40px] items-center justify-center">
        <Spinner size={5} />
      </List.Item>
    );
  }

  return (
    <List.Item className="group !mt-0 px-2 mx-5 h-[40px] relative flex items-center gap-2 py-2 hover:bg-t2-neutral-grey02 rounded-sm">
      <Link href={url} onClick={onClick} className="absolute inset-0 w-full z-0">
        {' '}
      </Link>
      <div className="flex items-center w-3 h-3 md:w-4 md:h-4">
        <SearchIcon />
      </div>
      <Typo
        variant="small-p"
        className="!text-sm multiline-ellipsis text-t2-neutral-grey05 group-hover:text-t2-neutral-grey09"
        style={getMultilineEllipsisStyle(1)}
      >
        {searchTerm}
      </Typo>
      <RemoveSearchHistoryEntryButton onRemoveButtonClick={handleRemoveButtonClick} />
    </List.Item>
  );
};
