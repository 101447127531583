import { ProfileId, useLogin } from '@lens-protocol/react-web';
import { usePrivy } from 't2-keystone/packages/privyAuthentication/src/hooks/usePrivy';
import { useCallback } from 'react';
import { useActiveLensAddress } from 'src/services/lens/hooks/useActiveLensAddress';
import { TLensPartialProfile } from './useProtectActionWithLensLogin';
import { useActiveLensProfile } from '../../../services/lens/hooks/useActiveLensProfile';
import { useLazyDefaultProfile } from '../../../services/lens/hooks/useLazyDefaultProfile';
import { useWalletConnection } from '../../../services/walletConnection/WalletConnectionContext';

export const useLoginWithLensViaPrivy = () => {
  const { login: privyLogin, user, authenticated } = usePrivy();
  const { data: activeLensAddress, loading: isActiveAddressLoading } = useActiveLensAddress();
  const { data: activeLensProfile } = useActiveLensProfile();
  const { execute: lensLogin, loading: isLoginLoading } = useLogin();
  const { execute: getDefaultProfile } = useLazyDefaultProfile();
  const { connectWalletAsync, connectedWallet } = useWalletConnection();

  const isLoading = isLoginLoading || isActiveAddressLoading;

  const loginWithLens = useCallback(async () => {
    if (!connectedWallet) {
      await connectWalletAsync?.();
    }

    let activePrivyAddress = user?.wallet?.address;
    if (!activePrivyAddress) {
      const loginResponse = await privyLogin();
      activePrivyAddress = loginResponse.wallet?.address!;
    }

    let profile: TLensPartialProfile | null = null;
    if (!activeLensAddress || activeLensAddress !== activePrivyAddress) {
      const defaultProfile = await getDefaultProfile(activePrivyAddress);
      if (!defaultProfile) {
        throw new Error('There is no default profile for this address');
      }

      const lensLoginResponse = await lensLogin({
        address: activePrivyAddress,
        profileId: defaultProfile?.id as ProfileId,
      });
      profile = lensLoginResponse.unwrap();
    } else {
      profile = activeLensProfile || null;
    }

    return profile;
  }, [activeLensProfile, activeLensAddress, lensLogin, privyLogin, user?.wallet?.address, authenticated]);

  return { loginWithLens, isLoading };
};
