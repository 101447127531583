import { LEARN_MORE_URL } from 'utils/constants';
import Link from 'next/link';
import Tooltip from '../Tooltip/Tooltip';
import Typo from '../Typography/Typo';
import Label from '../Label/Label';

type TNavbarTimePointsLabelProps = {
  isAuthenticated?: boolean;
  userArticleTimePoints: number;
};

const NavbarTimePointsLabel: React.FC<TNavbarTimePointsLabelProps> = ({ isAuthenticated, userArticleTimePoints }) => (
  <div className="flex justify-end">
    <Tooltip
      trigger={
        <Label
          backgroundColor={isAuthenticated ? 'darkGreen' : 'neutralGrey01'}
          textColor={isAuthenticated ? 'secondary' : 'primary'}
          size="large"
        >
          <Typo tag="span" variant="small-p" className="w-full md:hidden text-xs md:!text-[16px]">
            {userArticleTimePoints} t.p.
          </Typo>
          <Typo tag="span" variant="small-p" className="w-full hidden md:block text-xs md:!text-[16px]">
            {userArticleTimePoints} Time Points
          </Typo>
        </Label>
      }
      direction="bottom"
    >
      <Typo variant="small-p">
        {isAuthenticated
          ? 'This is the amount of time points you have earned from this post.'
          : 'This is how many time points you could have earned from reading this post.'}{' '}
        {isAuthenticated ? (
          <Link className="underline" href={LEARN_MORE_URL} passHref>
            Learn more
          </Link>
        ) : (
          <>
            <Link className="underline" href="/sign-in">
              Log in or Join
            </Link>{' '}
            to not miss out on this opportunity next time!
          </>
        )}
      </Typo>
    </Tooltip>
  </div>
);
export default NavbarTimePointsLabel;
