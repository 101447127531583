export interface ChevronIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const ChevronIcon: React.FC<ChevronIconProps> = ({ width = 14, height = 8, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M1 1L7 7L13 1" stroke="" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ChevronIcon;
