import cx from 'classnames';
import { transitionAnimationClassNames } from '../../../utils/transitionAnimationClassNames';

export const MacOSShortcutIcon = () => (
  <svg width="38" height="18" viewBox="0 0 38 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#F4F4F4" className="group-hover:fill-t2-shades-white" />
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="3.5"
      stroke="#F4F4F4"
      className={cx('fill-t2-shades-white group-hover:fill-t2-neutral-grey02', transitionAnimationClassNames)}
    />
    <path
      d="M6.56128 13C5.44528 13 4.72528 12.292 4.72528 11.188C4.72528 10.024 5.42128 9.34 6.78928 9.34H7.49728V8.02H6.78928C5.42128 8.02 4.72528 7.336 4.72528 6.172C4.72528 5.068 5.44528 4.36 6.56128 4.36C7.90528 4.36 8.33728 5.2 8.33728 6.208V7.204H9.65728V6.208C9.65728 5.2 10.0893 4.36 11.4333 4.36C12.5493 4.36 13.2693 5.068 13.2693 6.172C13.2693 7.336 12.5733 8.02 11.2053 8.02H10.4973V9.34H11.2053C12.5733 9.34 13.2693 10.024 13.2693 11.188C13.2693 12.292 12.5493 13 11.4333 13C10.0893 13 9.65728 12.16 9.65728 11.152V10.156H8.33728V11.152C8.33728 12.16 7.90528 13 6.56128 13ZM10.4973 6.184V7.204H11.2053C12.0573 7.204 12.4173 6.856 12.4173 6.172C12.4173 5.464 11.9973 5.188 11.4333 5.188C10.8093 5.188 10.4973 5.572 10.4973 6.184ZM6.78928 7.204H7.49728V6.184C7.49728 5.572 7.18528 5.188 6.56128 5.188C5.99728 5.188 5.57728 5.464 5.57728 6.172C5.57728 6.856 5.93728 7.204 6.78928 7.204ZM8.33728 9.34H9.65728V8.02H8.33728V9.34ZM6.56128 12.172C7.18528 12.172 7.49728 11.788 7.49728 11.176V10.156H6.78928C5.93728 10.156 5.57728 10.504 5.57728 11.188C5.57728 11.896 5.99728 12.172 6.56128 12.172ZM10.4973 11.176C10.4973 11.788 10.8093 12.172 11.4333 12.172C11.9973 12.172 12.4173 11.896 12.4173 11.188C12.4173 10.504 12.0573 10.156 11.2053 10.156H10.4973V11.176Z"
      fill="#929292"
      className={cx('group-hover:fill-t2-neutral-grey05', transitionAnimationClassNames)}
    />
    <rect
      x="20.5"
      y="0.5"
      width="17"
      height="17"
      rx="3.5"
      fill="#F4F4F4"
      className={cx('fill-t2-shades-white group-hover:fill-t2-neutral-grey02', transitionAnimationClassNames)}
    />
    <rect
      x="20.5"
      y="0.5"
      width="17"
      height="17"
      rx="3.5"
      stroke="#F4F4F4"
      className="group-hover:stroke-t2-neutral-grey02"
    />
    <path
      d="M27.2599 4.3V8.68L31.1839 4.3H32.7079L29.4559 7.852L33.0799 13H31.6159L28.6279 8.752L27.2599 10.264V13H26.0359V4.3H27.2599Z"
      fill="#929292"
      className={cx('group-hover:fill-t2-neutral-grey05', transitionAnimationClassNames)}
    />
  </svg>
);
