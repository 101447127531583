import React, { FC } from 'react';
import { useMutation } from '@apollo/client';
import Typo from '../Typography/Typo';
import { clearUserSearchHistoriesMutation } from './mutations/clearUserSearchHistories';
import Spinner from '../Spinner/Spinner';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';

type TSearchHistoryHeaderProps = {
  userId?: string;
};

export const SearchHistoryHeader: FC<TSearchHistoryHeaderProps> = ({ userId }) => {
  const [clearUserSearchHistoriesRequest, { loading: isLoading }] = useMutation(clearUserSearchHistoriesMutation, {
    variables: {
      userId,
    },
    refetchQueries: ['getSearchHistoryByUserId'],
  });
  const { sendEvent } = useAnalytics();

  const handleClearUserSearchHistories = async () => {
    sendEvent('search_history_clear_all_click');
    try {
      clearUserSearchHistoriesRequest();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <div className="px-4 flex justify-between">
      <Typo variant="small-p" className="!text-xs md:!text-sm text-t2-neutral-grey06">
        Recent
      </Typo>
      <button
        onClick={handleClearUserSearchHistories}
        className="!text-xs text-t2-primary-brandColor hover:text-t2-neutral-grey09"
      >
        {isLoading ? <Spinner size={5} /> : 'Clear All'}
      </button>
    </div>
  );
};
