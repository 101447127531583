import { isWithinInterval, addDays } from 'date-fns';

export const isWithinDays = (dateToCheck: string, numDays: number): boolean => {
  const formattedDateToCheck: Date = new Date(dateToCheck);
  const endDate: Date = addDays(formattedDateToCheck, numDays);

  const interval: { start: Date; end: Date } = { start: formattedDateToCheck, end: endDate };

  return isWithinInterval(new Date(), interval);
};
