import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import IconButton from '../IconButton/IconButton';
import FullScreenIcon from './Icons/FullScreenIcon';

type TNavbarFullScreenButtonProps = {
  onClick: () => void;
};

export const NavbarFullScreenButton = ({ onClick }: TNavbarFullScreenButtonProps) => {
  const { sendEvent } = useAnalytics();

  const handleClick = () => {
    sendEvent('navbar_full_screen_button_click');
    onClick();
  };

  return (
    <IconButton className="hidden md:flex" onClick={handleClick}>
      <FullScreenIcon className="w-5 h-5" />
    </IconButton>
  );
};
