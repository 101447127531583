import { ReactNode } from 'react';
import UserProfileImage from '../UserProfileImage/UserProfileImage';
import { TCloudinaryImageShape } from '../CloudinaryResponsiveImage/types';
import { BadgeIcon } from '../Badge/BadgeIcon';

type TNotificationCardProfileImageProps = {
  image: TCloudinaryImageShape;
  badgeIcon: ReactNode;
};

export const NotificationProfileImageWithBadge = ({ image, badgeIcon }: TNotificationCardProfileImageProps) => (
  <div>
    <div className="relative">
      <UserProfileImage profileImage={image as TCloudinaryImageShape} size="large" />
      <BadgeIcon position="bottom-right" icon={badgeIcon} />
    </div>
  </div>
);
