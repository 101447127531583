import React, { FC } from 'react';
import Image from 'next/legacy/image';
import DropdownMenuItemButton from '../Dropdown/DropdownClickableItem';
import HelpIcon from '../../../public/images/help-square-dark.svg';

type THelpOnboardingMenuItemProps = {
  onClick: () => void;
};

const HelpOnboardingMenuItem: FC<THelpOnboardingMenuItemProps> = ({ onClick }) => (
  <DropdownMenuItemButton onClick={onClick} leftAdornment={<Image className="w-4 h-4 md:w-6 md:h-6" src={HelpIcon} />}>
    Help
  </DropdownMenuItemButton>
);

export default HelpOnboardingMenuItem;
