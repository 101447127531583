import { gql } from '@apollo/client';
import { userFieldsFragment } from '../../User/fragments/userFieldsFragment';

export const getAuthenticatedItemQuery = gql`
  ${userFieldsFragment}
  query authenticatedItem {
    authenticatedItem {
      __typename
      ... on User {
        ...UserFieldsFragment
      }
    }
  }
`;
