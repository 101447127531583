import React from 'react';

export const CopyIcon = ({ className, color = 'black' }: { className?: string; color?: string }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 5.9635V3.5C6 2.67158 6.67155 2 7.5 2H20.5C21.3285 2 22 2.67158 22 3.5V16.5C22 17.3285 21.3285 18 20.5 18H18.0087"
      stroke={color}
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 6H3.5C2.67157 6 2 6.67157 2 7.5V20.5C2 21.3284 2.67157 22 3.5 22H16.5C17.3284 22 18 21.3284 18 20.5V7.5C18 6.67157 17.3284 6 16.5 6Z"
      stroke={color}
      strokeWidth="1"
      strokeLinejoin="round"
    />
  </svg>
);
