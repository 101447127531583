import React, { FC, useCallback, useEffect } from 'react';
import cx from 'classnames';
import Typo from '../Typography/Typo';
import { SearchIcon } from '../Icons/SearchIcon';
import { WindowsShortcutIcon } from '../Icons/WindowsShortcutIcon';
import { transitionAnimationClassNames } from '../../../utils/transitionAnimationClassNames';
import { useDetectOperatingSystem } from './hooks/useDetectOperatingSystem';
import { OperatingSystemType } from './types';
import { MacOSShortcutIcon } from '../Icons/MacOSShortcutIcon';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { NavbarShortcut } from './constants';

type TNavbarSearchButtonProps = {
  openModal: () => void;
  closeModal: () => void;
  isOpen?: boolean;
};

export const NavbarSearchButton: FC<TNavbarSearchButtonProps> = ({ openModal, closeModal, isOpen }) => {
  const operatingSystem = useDetectOperatingSystem();
  const { sendEvent } = useAnalytics();

  const handleShortcutKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (!isOpen && (event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        openModal();
      }

      if (isOpen && (event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        closeModal();
      }

      if (event.metaKey) {
        sendEvent('search_shortcut_open', { shortcut: NavbarShortcut.CmdK });
      }

      if (event.ctrlKey) {
        sendEvent('search_shortcut_open', { shortcut: NavbarShortcut.CtrK });
      }
    },
    [openModal, isOpen],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleShortcutKeyUp);

    return () => {
      window.removeEventListener('keydown', handleShortcutKeyUp);
    };
  }, [handleShortcutKeyUp]);

  const ShortcutIcon = [OperatingSystemType.MacOS, OperatingSystemType.IOS].includes(operatingSystem!) ? (
    <MacOSShortcutIcon />
  ) : (
    <WindowsShortcutIcon />
  );

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        aria-label="Search Button"
        className={cx(
          'hidden group lg:flex items-center justify-center h-10 md:w-[160px] lg:w-[250px] px-4 py-2 rounded-[31px] bg-t2-shades-white text-left hover:bg-t2-neutral-grey03',
          transitionAnimationClassNames,
        )}
        onClick={openModal}
      >
        <SearchIcon className="!stroke-t2-neutral-grey09 w-5 h-5" />
        <Typo
          variant="p2"
          className="ml-3 !text-sm md:!text-base text-t2-neutral-grey05 text-left group-hover:text-t2-neutral-grey09"
        >
          Search
        </Typo>
        <div className="ml-auto">{ShortcutIcon}</div>
      </div>
      <div
        role="button"
        tabIndex={0}
        aria-label="Search Button"
        className="lg:hidden flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full bg-t2-shades-white hover:bg-t2-neutral-grey03"
        onClick={openModal}
      >
        <SearchIcon className="!stroke-t2-neutral-grey09 w-5 h-5" />
      </div>
    </>
  );
};
