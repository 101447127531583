import { polygon, polygonAmoy } from 'viem/chains';
import { isLensProductionEnv } from '../../../config';

export const LENS_API_URL = 'https://api-v2.lens.dev/';

export const LENS_DEV_API_URL = 'https://api-v2-amoy.lens.dev/';

export const LENS_POST_APP_ID = 't2.world';

export const LENS_PUBLICATION_COMMON_METADATA = {
  appId: LENS_POST_APP_ID,
};

export const LENS_CHAIN = isLensProductionEnv ? polygon : polygonAmoy;
