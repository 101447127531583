import { useMutation } from '@apollo/client';
import { useReactiveAuthenticatedUser } from 'src/components/Auth/hooks/useAuthenticatedUser';
import { useCallback } from 'react';
import { useLogout } from '@lens-protocol/react-web';
import { connectUserWithLensMutation } from '../mutations/connectUserWithLensMutation';
import {
  updateProfileExceptionNotification,
  lensProfileNotFoundExceptionNotification,
  sessionAndWalletAddressMismatchExceptionNotification,
  walletConnectionDeclinedExceptionNotification,
  alreadyProcessingExceptionNotification,
  unexpectedExceptionNotification,
  missingProfileExceptionNotification,
  walletClientNotFoundExceptionNotification,
  walletConnectionWrongAccountExceptionNotification,
} from '../notifications';
import { useLoginWithLensViaPrivy } from './useLoginWithLensViaPrivy';
import { captureDebugMessage } from '../../../services/monitoring/debug';
import { captureException } from '../../../services/monitoring/captureException';
import { WalletConnectionError } from '../../../services/walletConnection/constants';

export const useConnectWithLens = () => {
  const { authenticatedUserId, refetch: refetchAuthenticatedUser } = useReactiveAuthenticatedUser() || {};
  const { loginWithLens, isLoading: isLoginWithLensLoading } = useLoginWithLensViaPrivy();
  const { execute: lensLogout, loading: isLogoutPending } = useLogout();
  const [connectWithLensRequest, { loading: isConnectWithLensLoading }] = useMutation(connectUserWithLensMutation);
  const isLoading = isLogoutPending || isConnectWithLensLoading || isLoginWithLensLoading;

  const handleConnectUserWithLens = useCallback(async () => {
    try {
      await connectWithLensRequest({
        variables: { id: authenticatedUserId },
      });
    } catch (error) {
      captureException(error);
      updateProfileExceptionNotification();
      await lensLogout();
    }
  }, [authenticatedUserId, lensLogout, connectWithLensRequest]);

  const connectWithLens = useCallback(async () => {
    try {
      const profile = await loginWithLens();
      if (!profile) {
        lensProfileNotFoundExceptionNotification();
        captureDebugMessage('Connecting with lens failed: profile not found');
        await lensLogout();
        return;
      }

      await handleConnectUserWithLens();
      await refetchAuthenticatedUser?.();
    } catch (error) {
      captureException(error);

      const reason = (error as any)?.reason;
      if (reason === 'WRONG_ACCOUNT') {
        sessionAndWalletAddressMismatchExceptionNotification();
        captureDebugMessage(`Connecting with lens failed: ${reason}`);
        return;
      }

      const message: string = (error as any)?.message || '';
      if (message) {
        captureDebugMessage(`Connecting with lens failed: ${message}`);
      }

      if (message === WalletConnectionError.Canceled) {
        walletConnectionDeclinedExceptionNotification();
        return;
      }

      if (message === WalletConnectionError.WrongAccount) {
        walletConnectionWrongAccountExceptionNotification();
        return;
      }

      if (message === 'Wallet client not found') {
        walletClientNotFoundExceptionNotification();
        return;
      }

      if (message.includes('Already processing')) {
        alreadyProcessingExceptionNotification();
        return;
      }

      if (message.includes('There is no default profile for this address')) {
        missingProfileExceptionNotification();
        return;
      }

      unexpectedExceptionNotification();
    }
  }, [refetchAuthenticatedUser, handleConnectUserWithLens, lensLogout, loginWithLens]);

  return { isLoading, connectWithLens };
};
