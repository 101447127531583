import React, { useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getNewNotificationsQuery } from './query/getNewNotificationsQuery';
import { useAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { useAsidePanelContext } from '../AsidePanel/contexts/AsidePanelContext';
import { showNotification } from '../NotificationBanner/showNotification';
import { NotificationsList } from './NotificationsList';
import { NOTIFICATIONS_INITIAL_ITEMS_COUNT, NOTIFICATIONS_NEXT_ITEMS_COUNT } from './constants';

export const NotificationsListContainer = () => {
  const { isOpen } = useAsidePanelContext();
  const { authenticatedUserId } = useAuthenticatedUser();

  const [getNotifications, { data, loading, fetchMore }] = useLazyQuery(getNewNotificationsQuery, {
    variables: { recipientId: authenticatedUserId, skip: 0, take: NOTIFICATIONS_INITIAL_ITEMS_COUNT },
    fetchPolicy: 'network-only',
    onError: () => {
      showNotification({
        message: 'An error has occurred, please refresh the page and try again.',
        type: 'error',
        title: 'Error',
      });
    },
  });

  useEffect(() => {
    if (isOpen) {
      getNotifications();
    }
  }, [isOpen]);

  const notifications = data?.notifications || [];
  const notificationsCount = data?.notificationsCount || 0;
  const hasMoreArticlesToLoad = notificationsCount > notifications?.length;

  const fetchMoreNotifications = useCallback(async () => {
    if (!hasMoreArticlesToLoad || loading || !notifications.length) {
      return;
    }

    await fetchMore({
      variables: {
        skip: notifications?.length,
        take: NOTIFICATIONS_NEXT_ITEMS_COUNT,
      },
    });
  }, [hasMoreArticlesToLoad, loading, notifications]);

  return (
    <NotificationsList
      notifications={notifications}
      notificationsCount={notificationsCount}
      loading={loading}
      fetchMoreNotifications={fetchMoreNotifications}
      isOpen={isOpen}
    />
  );
};
