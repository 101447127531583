import Cookies from 'js-cookie';
import React, { useEffect, useMemo } from 'react';
import { useRouter } from 'next/compat/router';
import { useReactiveAuthenticatedUser } from '../../Auth/hooks/useAuthenticatedUser';
import { useBannerContext } from '../../Banner/BannerContext';
import { EBannerType } from '../../Banner/types';
import { TerritoryBanner } from '../TerritoryBanner';
import { TerritoryUpdateBannerCookieValue } from '../types';

export const TERRITORY_UPDATE_DISMISSED_BANNER_COOKIE_PREFIX = 'has-dismissed-territory-update-banner';

const getTerritoryBannerCookieName = (address: string) =>
  `${TERRITORY_UPDATE_DISMISSED_BANNER_COOKIE_PREFIX}_${address}`;

const setHasDismissedBanner = (address: string) => {
  Cookies.set(getTerritoryBannerCookieName(address), TerritoryUpdateBannerCookieValue.true, { expires: 365 });
};

const getHasDismissedBanner = (address: string): boolean =>
  Cookies.get(getTerritoryBannerCookieName(address)) === TerritoryUpdateBannerCookieValue.true;

export const useTerritoryBanner = (territorySlug?: string) => {
  const router = useRouter();
  const { data: userData, loading: isLoading, isAuthenticated } = useReactiveAuthenticatedUser();
  const { showBanner, closeBanner } = useBannerContext();

  const hideTerritoryUpdateBanner = () => {
    closeBanner();
  };

  const onCloseClick = () => {
    if (isAuthenticated) {
      setHasDismissedBanner(userData?.user.publicAddress as string);
    } else {
      hideTerritoryUpdateBanner();
    }
  };

  const hasDismissedBanner = getHasDismissedBanner(userData?.user.publicAddress as string);

  const shouldShowBanner = useMemo(() => {
    if (!territorySlug || !router?.asPath) return false;
    const territoryPathPattern = new RegExp(`^/t/${territorySlug}(?:/|$)`);

    return territoryPathPattern.test(router.asPath);
  }, [router?.asPath, territorySlug]);

  const showTerritoryUpdateBanner = () => {
    if (isLoading || hasDismissedBanner || !shouldShowBanner) return;

    showBanner({
      type: EBannerType.THP_V2_UPDATE_BANNER,
      content: <TerritoryBanner onClick={onCloseClick} />,
      onClose: onCloseClick,
    });
  };

  useEffect(() => {
    if (shouldShowBanner && !hasDismissedBanner) {
      showTerritoryUpdateBanner();
    } else {
      hideTerritoryUpdateBanner();
    }
  }, [shouldShowBanner, hasDismissedBanner, isLoading]);
};
