import { MouseEvent } from 'react';
import { useAuthenticatedUser } from '../../Auth/hooks/useAuthenticatedUser';
import { showNotification } from '../../NotificationBanner/showNotification';

export const useBlockForDeactivatedUser = () => {
  const { isAuthenticated, data } = useAuthenticatedUser();
  const isUserDeactivated = data?.user?.deactivated;

  const showNotificationForBlockedUser = () => {
    showNotification({
      message: 'Your account has been deactivated. Please contact t2 support team.',
      type: 'error',
    });
  };

  const handleBlockedClick = (e?: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    showNotificationForBlockedUser();
    e?.stopPropagation();
    e?.preventDefault();
  };

  return {
    shouldBlock: isAuthenticated && isUserDeactivated,
    handleBlockedClick,
    showNotificationForBlockedUser,
  };
};
