import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import IconButton from '../IconButton/IconButton';
import { useNavbarModalContext } from '../NavbarModalComponent/context/NavbarModalContext';
import ShareIcon from '../Icons/ShareIcon';

export const NavbarShareButton = () => {
  const { sendEvent } = useAnalytics();
  const { handleOpenArticleShareModal } = useNavbarModalContext();

  const handleShareButtonClick = () => {
    sendEvent('navbar_share_post_button_click');
    handleOpenArticleShareModal();
  };

  return (
    <IconButton onClick={handleShareButtonClick}>
      <ShareIcon className="w-4 h-4 md:w-5 md:h-5" />
    </IconButton>
  );
};
