import { NavbarNotificationBellIcon } from '../Navbar/NavbarNotificationBellIcon';
import IconButton from '../IconButton/IconButton';
import { BadgeIcon } from '../Badge/BadgeIcon';

type TNavbarNotificationBellProps = {
  onClick: () => void;
  hasNotifications: boolean;
};

export const NavbarNotificationBell = ({ onClick, hasNotifications }: TNavbarNotificationBellProps) => (
  <IconButton onClick={onClick} className="relative">
    <NavbarNotificationBellIcon />
    {hasNotifications && (
      <BadgeIcon
        icon={<span className="w-2 h-2 md:w-3 md:h-3 absolute top-0 right-0 inline-block rounded-full bg-orange-500" />}
      />
    )}
  </IconButton>
);
